.buttonContainer {
    margin-top: auto;
    padding-top: 1rem;
}

.chooseFileButton {
    background-color: #D6D8DC;
    border: none;
    padding: 0.25rem 0.5rem;

    &:focus {
        outline: none;
    }
}

.chooseFileContainer {
    padding-top: 1.5rem;
}

.container {
    padding: 1rem;
}

.dropContainer {
    background-color: #FFFFFF;
    border: 2px dashed #D1D1D1;
    color: #000000;
    outline: none;
    padding: 2rem;
    text-align: center;
}

.dropContainerText {
    padding: 2rem;
}

.nameContainer {
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.supportedFilesText {
    padding: 0.5rem;
}

.uploadButton {
    font-size: 1rem;
    margin-right: 1rem;
    width: 125px;
}

.uploadButtonContainer {
    text-align: right;
}

.uploadedModal {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
