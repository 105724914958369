.icon {
    padding: 0.5rem;
    position: absolute;
    top: 0;
    right: 0
}

.iconContainer {
    position: relative;
}
