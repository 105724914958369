@import '../../../../../colors.scss';

.mapContainer {
    height: 18rem;
}

.mapFinishMark {
    background-color: $RED;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    height: 1rem;
    position: absolute;
    top: calc(100% - 1rem);
    width: 1rem;
}

.mapRoute {
    background-color: $SRAM_400;
    border-radius: 0.5rem;
    height: 100%;
    margin: 0 0.5rem;
    padding: 1rem 0 1rem 0;
    position: relative;
    width: 1rem;
}

.mapStartMark {
    background-color: $GREEN_FOLAGE;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    height: 1rem;
    top: 0;
    position: absolute;
    width: 1rem;
}

.mapStationaryZone {
    background-color: $AXS_TEXT_DARK;
    border-radius: 0px;
    position: absolute;
    width: 1rem;
}

.stationaryMapContainer {
    height: 100%;
    overflow: hidden;
    position: relative;
}
