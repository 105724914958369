@import '../../../constants/scss/constants.scss';
@import '../../../index.scss';

.link {
    align-items: center;
    background-color: $SRAM_300;
    border: none;
    border-radius: 3px;
    color: $BLACK;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    text-decoration: none;
    min-width: 120px;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: $SRAM_400;
        color: $BLACK;
        outline: none;
        text-decoration: none;
    }
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .link {
        background-color: $TRANSPARENT;
        color: $SRAM_700;

        &:active {
            background-color: $TRANSPARENT;
        }

        &:focus {
            background-color: $TRANSPARENT;
        }

        &:hover {
            background-color: $TRANSPARENT;
        }
    }
}
