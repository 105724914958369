@import '../../colors.scss';
@import '../../constants/scss/constants.scss';
@import '../../index.scss';

.axsContainer {
    font-size: 1.17rem;
    padding: 1.5rem;
}

.cardBanner {
    padding-top: 30%;
}

.cardContainer {
    background-color: $WHITE;
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    margin-bottom: 2rem;
}

.cardHeader {
    align-items: center;
    background-color: $SRAM_400;
    display: flex;
    font-size: 1.33rem;
    height: 4.19rem;
    padding-left: 2rem;
}

.cardTitle {
    font-weight: bold;
    text-transform: uppercase;
}

.desktopBottomBar {
    background-color: $WHITE;
    justify-content: flex-end;
    padding: 1.25rem;
}

.headerSlope {
    border-left: 20px solid $TRANSPARENT;
    border-top: 20px solid $WHITE;
    position: absolute;
    right: 0;
    top: 0;
    width: 70%;
}

.optionsButton {
    background-color: $TRANSPARENT;
    border: none;

    &:focus {
        outline: 0;
    }
}

.optionsIcon {
    height: 2.25rem;
    width: auto;
}

.optionsIconContainer {
    bottom: 1rem;
    color: $WHITE;
    height: 2.67rem;
    position: absolute;
    right: 1rem;
    z-index: 1;
}

.quarqHeader {
    position: relative;
}

.quarqLogo {
    height: 1.25rem;
}

.retiredCard {
    display: flex;
    justify-content: space-between;
}

.retiredText {
    color: $AXS_TEXT_LIGHT_GREY;
    font-weight: initial;
    text-transform: initial;
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .headerSlope {
        width: 60%;
    }

    .quarqLogo {
        height: 1rem;
    }
}
