@import '../../constants/scss/constants.scss';
@import '../../colors';

@keyframes modalSlide {
    from {
        transform: translateY(115%);
    }

    to {
        transform: translateY(0);
    }
}

.animate {
    animation: modalSlide 0.5s ease-out forwards;
}

.applyButton {
    background-image: linear-gradient($PINK, $SRAM_RED 50%);
    border: none;
    border-radius: 4px;
    color: $WHITE;
    font-weight: bold;
    padding: 1rem 2rem;
    text-transform: uppercase;
    width: 20rem;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.buttonContainer {
    height: 4rem;
    margin-bottom: 1.5rem;
    margin-top: auto;
    text-align: center;
    width: 100%;
}

.closeButton {
    background-color: $TRANSPARENT;
    border: none;
    color: $AXS_TEXT_GREY;
    font-weight: bold;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.container {
    background-color: $WHITE;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    left: 0px;
    max-height: 100vh;
    outline: none;
    overflow: auto;
    position: fixed;
    text-align: center;
    width: 100%;
}

.content {
    padding: 1rem;
    padding-top: 0;
}

.fullscreen {
    height: 100vh;
}

.header {
    background-color: $SRAM_100;
    font-size: 1.5rem;
    min-height: 4.5rem;
    padding: 1rem 0.25rem;
    text-align: right;
}

.overlay {
    align-items: center;
    background-color: $OPAQUE_DARK;
    bottom: 0px;
    justify-content: center;
    left: 0px;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 99;
}

.subheader {
    font-size: 1.25rem;
    padding: 1rem;
    text-align: left;
}

// +1 in the media query is specifically for width: 768px (i-Pad)
// the drawer does not render on i-Pad if +1px is explicitly not provided in the media query.
@media (min-width: (map-get($grid-breakpoints, md) + 1)) {
    .overlay {
        display: none;
    }
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .container {
        padding-bottom: calc(50px + env(safe-area-inset-bottom));
    }

    .content {
        padding-bottom: 0;
    }
}
