@import '../../../../constants/scss/constants.scss';
@import '../../../../index.scss';

.backButton {
    align-items: center;
    background-color: $SRAM_300;
    border: none;
    border-radius: 3px;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    text-decoration: none;
    max-width: 120px;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: $SRAM_400;
        outline: none;
        text-decoration: none;
    }
}

.buttonContainer {
    display: flex;
    justify-content: space-around;
    margin-top: auto;
    padding-top: 1rem;
    width: 100%;
}

.chainstayInput {
    border: none;
    height: 2.5rem;
    max-width: 80px;
    text-align: center;
}

.container {
    display: flex;
    flex-direction: column;
    max-width: map-get($grid-breakpoints, lg);
    padding: 2rem;
}

.content {
    padding-top: 2rem;
}

.customBike {
    font-size: 1.5rem;
    font-weight: 500;
    padding: 1rem 0;
}

.customBikeDescription {
    color: $SRAM_700;
    font-size: 1rem;
}

.footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
}

.image {
    margin: 1rem auto;
    max-width: 320px;
}

.imageContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
}

.infoContent {
    color: initial;
    text-align: left;
}

.infoDetail {
    font-size: 14px;
    font-weight: 400;
}

.infoHeader {
    font-size: 20px;
    font-weight: 500;
}

.infoSubHeader {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 30px;
}

.label {
    font-size: 1.25rem;
    font-weight: 500;
    padding-bottom: 1rem;
}

.modalContainer {
    padding: 1rem 2rem;
    max-width: map-get($grid-breakpoints, md);
    min-height: 30vh;
}

.nextButton {
    background-image: linear-gradient($PINK, $SRAM_RED 50%);
    border: none;
    border-radius: 4px;
    color: $WHITE !important;
    font-size: 1.25rem;
    font-weight: 1000;
    max-width: 30rem;
    padding: 1.25rem 2rem;
    text-transform: uppercase;
    width: 100%;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        color: $WHITE;
        text-decoration: none;
    }
}

.noticeModalContainer {
    min-height: auto;
}

.noticeContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid $SRAM_500;

    &:last-child {
        border: none;
    }
}

select.inputField {
    appearance: none;
    background-color: $WHITE;
    background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%20%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%20%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2214px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2014%2012%22%20enable-background%3D%22new%200%200%2014%2012%22%20xml%3Aspace%3D%22preserve%22%3E%20%3Cpolygon%20points%3D%223.862%2C7.931%200%2C4.069%207.725%2C4.069%20%22/%3E%3C/svg%3E);
    background-position: 98% 50%;
    background-repeat: no-repeat;
    background-size: 2rem auto, 100%;
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
    height: 2.25rem;
    padding: 0 1rem;
    width: 100%;
}

.setupGuideContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.toggleButton {
    background-color: $SRAM_800;
    border: 2px solid $SRAM_800;
    border-radius: 7px;
    color: $WHITE;
    font-size: 1.25rem;
    font-weight: 1000;
    padding: .75rem;
    text-transform: uppercase;
    width: 50%;

    &:hover {
        background-color: $SRAM_800;
        border-color: $SRAM_800;
    }
}

.toggler {
    background-color: $SRAM_800;
    border: 3px solid $SRAM_800;
    border-radius: 7px;
    max-width: 450px;
    width: 100%;
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .setupGuideContainer {
        flex-direction: column-reverse;
    }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
    .container {
        padding: 1rem 0;
    }

    .toggleButton {
        font-size: 1rem;
        padding: .75rem .25rem;
        white-space: nowrap;
    }
}
