@import '../../../../colors';
@import '../../../../constants/scss/constants.scss';

.axis {
    text {
        font-size: 1rem;
    }
}

.chart {
    overflow: visible;
}

.chartContainer {
    height: calc(100vh - 13rem);
    max-height: 400px;
    overflow: hidden;
    padding-bottom: 1.675rem;
    padding-left: 3.25rem;
    padding-top: 1rem;
}

.container {
    padding: 0 1rem;
    padding-bottom: 2rem;
}

.footer {
    padding: 1.5rem;
}

.noDataNote {
    align-items: center;
    display: flex;
    font-size: 1.5rem;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
}

.xAxisLabel {
    text-align: center;
}

.yAxisLabel {
    text-align: left;
}

@media (max-width: map-get($grid-breakpoints, sm)) {
    .axis {
        text {
            font-size: 0.7rem;
        }
    }
    .chartContainer {
        padding-bottom: 1.29rem;
        padding-left: 2.5rem;
        padding-top: 0.25rem;
    }
}
