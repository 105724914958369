@import '../../../index.scss';
@import '../../../constants/scss/constants.scss';



.modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-inline: 0;
    width: 100%;
    height: fit-content;
    max-height: 80%;
    background-color: $SRAM_900;
    color: white !important;
    align-self: flex-end;
    animation: slide-up 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    margin-bottom: calc(env(safe-area-inset-bottom));
    border-radius: 10px;
    @keyframes slide-up {
        0% {
            transform: translateY(110%);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }


    .content{
        .header{
        color: white;
    }
}
    }

.withSearch{
    height: 80%;
}

.unavailableModalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 15%;
    width: 100%;
    height: 100%;
    background-color: transparent;



    .infoContent{
        padding: 0;
        color: white;
        background-color: $SRAM_900;
        border-radius: 10px;
        padding-inline: 0;
        font-size: 1.3rem;
        padding-top: 1rem;

        .button{
            border-top: 1px solid rgba($SRAM_600, 0.3);
            border-radius: 2px;
            color: $WHITE;
            font-size: 1rem;
            font-weight: 300;
            padding: 3px;
            width: 100%;
            padding: .8rem;

          &:active {
                background-color: $SRAM_800;
            }
        }


        .infoText{
            text-align: center;
        }
    }
}

.infoContent{
    padding: 0;
    color: white;  
    
}

.inputContainer{
    padding-inline: 1rem;
    padding-top: 1rem;
}

.searchInput{
    background-color: $SRAM_800;
    border: none;
    border-radius: 2px;
    color: $WHITE;
    font-size: 1.1rem;
    font-weight: 300;
    padding: 3px;
    width: 100%;
    margin-bottom: 1rem;
    padding-inline-start: .5rem;
} 

.modalContainerAlt{
    border-radius: 50px;
    padding: none;
    position: absolute;
    right: 10px;
    top: 44px;
    width: 240px;   
    box-shadow: 6px 10px 18px -7px rgba(0,0,0,0.49);
}

.listContainerAlt{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-color: #313133;
    border-radius: 7px;
    overflow: hidden;
    z-index: 999;

}

.listItemAlt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 10px 20px;
    cursor: pointer;
    color: white;

  button{
    color: white;
    width: 100%;
    text-align: left;
  }
}

.listContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding-top: 2rem;
    background-color: $SRAM_900;
}


.listItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0.5rem;
    cursor: pointer;
    color: white;
    border-bottom: 1px solid $SRAM_600;

  button{
    color: white;
    width: 100%;
    text-align: left;
  }
}


.infoText{
    padding-inline: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: white;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 300;
    line-height: 20px;
    text-align: left;

}

.imageContainer{
    width: 100%;
    padding: 2rem 2rem 2rem 2rem;
    font: SF Pro;
    font-size: 17px;
    font-weight: 510;
    text-align: left;
}

.image{
    border-radius: 10px;
}

.modalContainerInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-inline: 0;
    width: 100%;
    height: fit-content;
    background-color: $BLACK;
    color: white !important;
    align-self: flex-end;
    animation: slide-up 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    border-radius: 10px;

      @keyframes slide-up {
        0% {
            transform: translateY(110%);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .content{
        .header{
        color: white;
    }
}
    }

