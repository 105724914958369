@import '../../../colors';

.border {
    border-top: 1px solid $SRAM_200;
}

.container {
    align-items: center;
    display: flex;
}

.selection {
    text-transform: capitalize;
    white-space: nowrap;
}
