@import '../../../colors.scss';

.button {
    height: 100%;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;

    &:hover {
        text-decoration: none;
    }
}

.container {
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    margin-bottom: 1rem;
    position: relative;
}

.footerContainer {
    align-items: center;
    background-color: $WHITE;
    display: flex;
    padding: 1.25rem;
    justify-content: space-between;
}

.headingContainer {
    color: $AXS_TEXT_DARK;
    font-size: 1.33rem;
    text-transform: uppercase;
    font-weight: 700;
}

.subText {
    color: $AXS_TEXT_GREY;
    font-size: 1.33rem;
    font-weight: 300;
    padding-right: 1rem;
}
