@import '../../index.scss';

.container {
    color: $SRAM_700;
    font-size: .75rem;
    text-align: left;
}

.compatibleList {
    font-weight: normal;
}

.point {
    font-weight: bold;
    padding-left: 2rem;
}
