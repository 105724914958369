@import '../../colors.scss';

.collapsableContainer {
    background-color: $TRANSPARENT;
    overflow: hidden;
    transition: max-height 0.2s;
}

.toggleButton {
    background-color: $TRANSPARENT;
    border: none;
    outline: none;
    padding: 0px;
    text-align: start;
    width: 100%;

    &:focus {
        outline: none;
    }
}
