@import '../../constants/scss/constants.scss';
@import '../../index.scss';

.container {
    max-width: 100dvw;
    min-height: 100dvh;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 0;
    background-color: #1C1C1E;
    overflow: auto;
}

.customBikeInfo{
    color: $WHITE;
    padding: 20px 20px;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 44px;
    padding: 0;
    background-color: $BLACK;
    padding: .7rem;
}

.helpButton {
    background: none;
    border: none;
    color: $WHITE;

    &:focus {
        outline: none;
    }
}

.helpSection {
    position: absolute;
    top: .5rem;
    right: .5rem;
}

.title {
    font-size: 1.2rem;
    padding: 0 0.5rem;
    color: $WHITE;
    font: 'Roboto', sans-serif;
    line-height: 20px;
}

.description {
    font-size: 14px;
    color: $WHITE;
}

.mobileContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 20px 16px 20px;
    width: 100%;
    background-color: #1C1C1E;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    background-color: #1C1C1E;
    border-bottom: 1px solid $SRAM_600;
    padding: 10px 20px;
    cursor: pointer;
    color: $WHITE;
    z-index: 1;

    select{
        border: none;
    }
    .selectorTitle{
        font-size: 14px;
        color: $WHITE;
    }
}

.optionsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0rem;
    width: 100%;
    background-color: #1C1C1E;
    height: auto;
    position: relative;
}

.disabled {
    opacity: 0.5;
}

.formBtns{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top:  auto;
    width: 100%;
    padding: 1rem;
}

.footer {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
      gap: 1rem;
}

.infoLink {
    color: $WHITE;
    text-decoration: underline;
    font-size: 1rem;
    font-weight: 1000;
    padding: 0;
    margin: 0;
    padding-inline: .5rem;
    &:hover {
        color: $WHITE;
        text-decoration: none;
    }
}

.submitButton {
    background-color: $SRAM_500;
    border: none;
    border-radius: 4px;
    color: $BLACK;
    font-size: 1.25rem;
    font-weight: 1000;
    text-transform: uppercase;
    width: 265px;
    height: 44px;

    &:active {
        outline: none;
        background-color: $WHITE;
    }

}

.toggleButton {
    background-color: $SRAM_800;
    border: 2px solid $SRAM_800;
    color: $WHITE;
    font-size: .7rem;
    font-weight: 1000;
    text-transform: uppercase;
    width: 50%;
    height: 100%;
    padding: 0;

    &:hover {
        background-color: $SRAM_800;
        border-color: $SRAM_800;
    }
}

.toggler {
    background-color: $SRAM_800;
    border: 3px solid $SRAM_800;
    border-radius: 7px;
    max-width: 500px;
    min-width: 230px;
    max-height: 100%;
    height: 35px;
}


.resetBtn {
    border: none;
    border-radius: 4px;
    color: $WHITE !important;
    font-size: 1rem;
    text-transform: uppercase;
    width: 265px;
    height: 44px;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        color: $WHITE;
        text-decoration: none;
    }
}
