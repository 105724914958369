@import '../../../constants/scss/constants.scss';
@import '../../../index.scss';

.container {
    display: flex;
    height: 100%;
    margin-left: 1rem;
}

.image {
    height: 3rem;
    width: auto;
}

.link {
    color: $WHITE;
    display: block;
    font-size: 1.17rem;
    font-weight: bold;
    margin: auto;
    text-transform: uppercase;
    white-space: nowrap;

    &:hover {
        color: $WHITE;
        text-decoration: none;
    }
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .container {
        margin: auto;
    }
}
