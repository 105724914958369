@import '../../../constants/scss/constants.scss';
@import '../../../index.scss';

.desktopContainer {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
}

.desktopShareMethods {
    justify-content: center;
}

.header{
    display: flex;
    flex-direction: row;
}

.mobileContainer {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
}

.notPublicText {
    font-size: 1.25rem;
    margin: auto 0;
    text-align: left;
}

.mobilePublicToggleContainer{
    margin: auto;
    margin-right: 0;
}

.shareButton {
    background-color: $TRANSPARENT;
    border: none;
    margin: 0 0.5rem 0 0.5rem;
}

.shareButtonContent {
    height: 3.5rem;
    width: 3.5rem;
}

.shareMethods {
    display: flex;

    flex-direction: row;
    flex-wrap: wrap;
    min-height: 5rem;
    padding: 0.5rem;
    padding-bottom: 0;
}

.title {
    font-size: 1.25rem;
    text-align: left;
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .desktopContainer {
        display: none;
    }
}
