@import '../../../colors';

.container {
    align-items: baseline;
    display: flex;
    justify-self: center;
    max-width: 492px;
    text-align: left;
    width: 100%;
}

.content {
    color: $SRAM_700;
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 1rem;
    padding-left: 1rem;
}

.icon {
    height: 1rem;
    width: auto;
}

.title {
    color: $SRAM_800;
    font-size: 1.17rem;
    font-weight: 700;
    text-transform: capitalize;
}
