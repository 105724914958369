@import '../../constants/scss/constants.scss';
@import '../../colors.scss';

.alertBadgeContainer {
    align-items: center;
    display: flex;
    height: 100%;
    left: -0.75rem;
    position: absolute;
    top: 0;
    z-index: 2;
}

.alertBadgeImage {
    height: 1.5rem;
    width: auto;
}

.container {
	position: relative;
}

.imageOverlayContainer {
    background-image: linear-gradient(to bottom, $TRANSPARENT calc(100% - 75px), $BLACK 100%);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.title {
    bottom: 1rem;
    color: $WHITE;
    font-family: 'Roboto', sans-serif;
    font-size: 1.25rem;
    left: 1rem;
    padding-top: 0.75rem;
    position: absolute;
    text-align: left;
}

.tag {
    height: 40px;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 100px;
}

.tagBackground {
    background-color: $WHITE;
    height: 100%;
    opacity: 0.8;
    width: 100%;
}

.tagImage {
    bottom: 0px;
    height: 30px;
    left: 0px;
    margin: auto;
    position: absolute;
    right: 0px;
    top: 0px;
    width: auto;
}

@media (max-width: map-get($grid-breakpoints, xs)) {
    .title {
        font-size: 1.5rem;
    }
}

@media (min-width: map-get($grid-breakpoints, xs))
    and (max-width: map-get($grid-breakpoints, sm))
{
    .title {
        font-size: 1.8rem;
    }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
    .alertBadgeContainer {
        left: -0.625rem;
    }

    .alertBadgeImage {
        height: 1.25rem;
        width: auto;
    }

    .tag {
        height: 35px;
        width: 75px;
    }

    .tagImage {
        height: 20px;
    }
}

@media (min-width: map-get($grid-breakpoints, sm))
    and (max-width: map-get($grid-breakpoints, md))
{
    .title {
        font-size: 2.10rem;
    }
}

@media (min-width: map-get($grid-breakpoints, md)) {
    .title {
        font-size: 2.25rem;
    }
}
