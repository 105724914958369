@import '../../../../colors.scss';

.clickableRow {
    background-color: $TRANSPARENT;
    border: 0;
    color: $BLACK;
    padding: 0;
    position: relative;
    width: 100%;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.editDrivetrain {
    text-align: center;
}

.footer {
    padding: 1.5rem 0;
}

.inactive {
    color: $SRAM_300;
}

.toggle {
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem 0.5rem;
}
