@import '../../index.scss';

.button {
    padding: 3rem;
}

.container {
    font-size: 1.25rem;
    text-align: center;
}

.content {
    margin-bottom: 2rem;
}

.contentModal {
    overflow-y: auto;
}

.description {
    padding: 0 3rem;
}

.header {
    font-size: 1.5rem;
    font-weight: lighter;
    padding: 1rem 0 0.5rem 0;
}

.image {
    height: 3rem;
    margin-top: 2rem;
    width: auto;
}

.modalContainer {
    padding: 0;
}

.modalContent {
    color: initial;
    display: flex;
    flex-direction: column;
    padding: 0;
    position: relative;
}

.subtitle {
    font-weight: bold;
    padding: 2rem;
}
