@import '../../../constants/scss/constants.scss';
@import '../../../colors.scss';

.container {
    display: flex;
    flex-direction: row;
    max-width: 475px;
    padding: 0.5rem 0;
}

.content {
    display: flex;
    max-width: 200px;
    width: 100%;
}

.label {
    color: $AXS_TEXT_DARK;
    font-size: 1.17rem;
    height: 100%;
    margin: 0;
    padding-right: 1rem;
    text-align: right;
    min-width: 175px;
}

@media (max-width: map-get($grid-breakpoints, sm)) {
    .container {
        flex-direction: column;
        margin-left: 2rem;
    }

    .content {
        width: auto;
    }

    .label {
        text-align: left;
        font-weight: 500;
        padding-bottom: .5rem;
        padding-top: .5rem;
        width: auto;
    }
}
