@import '../../index.scss';
@import '../../constants/scss/constants.scss';

.container {
    background: $AXS_WEB_BG;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 0 1rem;
    text-align: center;
}

.imageZipp {
    height: 1.75rem;
    margin: auto 0.5rem;
    width: auto;
}

.header {
    display: inline-block;
    font-weight: bold;
    margin: auto 0.5rem;
    text-transform: uppercase;
}

.headerContainer {
    color: $AXS_TEXT_DARK;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1rem;
    margin-left: 4rem;
    margin-right: 4rem;
    padding-bottom: 0.5rem;
    padding-top: 1.25rem;
}


@media only screen and (max-width: map-get($grid-breakpoints, md)) {
   .container{
         padding: 0;
   }
}
