@import '../../index.scss';

.headerContainer {
    align-items: center;
    background-color: $SRAM_300;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.content {
    background-color: $WHITE;
    flex: 1;
    padding: 2rem;
    position: relative;
}

.image {
    bottom: 0;
    height: auto;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 90%;
}

.imageContainer {
    background-color: $SRAM_RED;
    color: $WHITE;
    height: 4rem;
    margin-bottom: 1rem;
    position: relative;
    width: 4rem;
}

.emailSentContainer {
    display: block;
    height: 1.5rem;
    margin: 1rem auto;
    width: 1.5rem;
}

.emailSentErrorIcon {
    height: 100%;
    width: auto;
}

.emailSentSuccessIcon {
    border: 1px solid $GREEN_FOLAGE;
    border-radius: 50%;
    color: $GREEN_FOLAGE;
    font-size: 1rem;
    height: 100%;
    text-align: center;
    width: 100%;
}

.resendButton {
    background-color: $TRANSPARENT;
    border: none;
    color: $AXS_TEXT_LIGHT_GREY;
    font-size: 1rem;
    font-weight: 300;
    outline: none;
    padding: 0;
    text-decoration: underline;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        color: $BLUE_CHALK;
    }
}

.resendContainer {
    background-color: $WHITE;
    padding: 1rem;
}

.refreshButton {
    background-color: $SRAM_600;
    border: none;
    color: $WHITE;
    display: inline-block;
    font-weight: 500;
    padding: 2rem;
    text-transform: uppercase;
    width: 100%;

    &:hover {
        color: $BLACK;
        text-decoration: none;

        >.refreshButtonText {
            color: $WHITE;
            transform: scale(1.05);
            -webkit-transform: scale(1.05);
        }
    }
}

.refreshButtonText {
    display: block;
    transition: 0.2s ease-in-out transform;
    -webkit-transition: 0.2s ease-in-out transform;
}

.title {
    color: $BLACK;
    font-weight: 400;
    font-size: 1.5rem;
}

.modalContent {
    padding: 0;
}
