@import '../../index.scss';

.buttonContainer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.content {
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
}

.formContainer {
    background-color: $WHITE;
    padding-top: 2rem;
}

.saveButton {
    font-size: 1rem;
    margin: 2rem;
    width: 125px;
}
