@import '../../../../index.scss';

.container {
    padding-right: 1rem;
}

.durationLabel {
    font-size: 1rem;
    margin-left: auto;
    padding-right: 1rem;
}

.gearTitle {
    font-size: 1.25rem;
    font-weight: bold;
}

.gearModel {
    font-size: 1.15rem;
}

.heading {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}

.legendContainer {
    font-size: 1rem;
    margin-bottom: 0.25rem;
    padding-right: 1rem;
    text-align: right;
}

.legendIcon {
    background-color: $SRAM_RED;
    display: inline-block;
    height: 0.6rem;
    margin-right: 0.5rem;
    width: 0.6rem;
}

.row {
    display: flex;
    padding-left: 60px;
    width: 100%;
}

.rowLabel {
    line-height: 1;
    margin: 5px 0;
}

.toggle {
    padding: 1.5rem 0;
    text-align: right;
}
