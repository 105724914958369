@import '../../index.scss';

.blipContainer {
    height: 2rem;
    width: 2rem;
}

.buttonHover {
    &:hover {
        background-color: #3C4043;
    }
}

.menuItemButton {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:hover {
        > span {
            border-color: #e51937;
        }
    }
}

.navBarLabelContainer {
    align-items: center;
    justify-content: space-between;
}

.plus {
    align-items: center;
    border: 1px solid #7b7c7f;
    border-radius: 50%;
    display: flex;
    font-size: 2rem;
    font-weight: 800;
    height: 2rem;
    justify-content: center;
    width: 2rem;

    &:hover {
        color: #e51937;
        text-decoration: none;
    }
}

.axsMenuIconContainer {
    @extend .flex;

    align-items: center;
}
