@import '../../../index.scss';

.row {
    border-bottom: 1px solid $SRAM_600;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    position: relative;
    min-height: 50px;
    width: 100%;

    &:last-of-type {
        border-bottom: none;
    }
}

.middleBorder {
    background-color: $SRAM_600;
    min-height: inherit;
    margin: auto;
    min-width: 1px;
    width: 1px;
}

.column {
    flex: 1;
    text-align: center
}

.borderBottomNone {
    border-bottom: none;
}
