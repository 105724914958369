@import '../../colors';
@import '../../constants/scss/constants.scss';
@import '../../index.scss';

.container {
    align-items: center;
    background-color: $WHITE;
    border: none;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    display: flex;
    justify-content: center;
    max-width: 592px;
    min-width: 1px;
    outline: none;
    overflow: auto;
    padding: 1rem;
    position: relative;
    width: 100%;
    max-height: calc(100vh - 2rem);
    min-height: 40vh;
}

.content {
    align-self: stretch;
    color: $AXS_TEXT_LIGHT_GREY;
    font-size: 1.17rem;
    font-weight: 300;
    min-width: 1px;
    overflow-y: auto;
    padding: 1rem;
    text-align: center;
    width: 100%;
    z-index: 0;
}

.closeButton {
    background: url(../../assets/icons/grayX.svg) no-repeat;
    border: none;
    float: right;
    height: 36px;
    margin: 1rem;
    object-fit: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 36px;

    &:focus {
        outline: none;
    }

    &:hover {
        background: url(../../assets/icons/grayXDark.svg) no-repeat;
    }
}
.darkModeCloseBtn {
    background: url(../../assets/icons/grayXDarkMode.svg) no-repeat;
    border: none;
    float: right;
    height: 36px;
    margin: 1rem;
    object-fit: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 36px;

    &:focus {
        outline: none;
    }

    &:hover {
        background: url(../../assets/icons/grayXDark.svg) no-repeat;
    }
}

.dialog {
    max-width: 25rem;
    min-height: 0;
}

.image {
    display: block;
    height: 50px;
    margin: auto;
}

.header {
    color: $AXS_TEXT_DARK;
    font-size: 1.75rem;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 0rem;
    text-align: center;
}

.headerMarginTopNone {
    margin-top: 0;
}

.overlay {
    display: flex;
    align-items: center;
    background-color: $OPAQUE_DARK;
    bottom: 0px;
    justify-content: center;
    left: 0px;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 99;
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .container {
        max-height: calc(100vh - 8rem);
    }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
    .closeButton {
        height: 25px;
        margin: 0.5rem;
        width: 25px;
    }
}
