@import '../../colors.scss';
@import '../../constants/scss/constants.scss';

.alertImage {
    height: 20px;
    margin-bottom: auto;
    margin-top: auto;
    margin-right: 0.5rem;
    width: 20px;
}

.container {
    align-content: center;
    background-color: $WHITE;
    box-shadow: 0 6px 4px 0 $OPAQUE_LIGHTEST, 0 3px 3px 0 $OPAQUE_LIGHTEST;
    border-radius: 3px;
    display: flex;
    padding: .5rem;
    width: 100%;
    word-break: break-word;
}

.content {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.dateContainer {
    font-size: .70rem;
}

.title {
    color: $AXS_TEXT_DARK;
    font-size: 1.17rem;
    font-weight: 700;
    text-transform: capitalize;
}

// @media (max-width: map-get($grid-breakpoints, sm)) {
//     .title {
//         font-size: 1rem;
//     }
// }
