@import '../../../../../colors.scss';
@import '../../../../../constants/scss/constants.scss';
@import '../../../../../index.scss';

.assignBikeContainer {
    padding: 2rem;
    text-align: left;
    width: 100%;
}

.bikeButtonsContainer {
    padding-top: 2rem;
}

.bikeChoice {
    margin-top: 1rem;
}

.bikeText {
    margin: auto;
}

.button {
    margin-left: auto;

    &:last-of-type {
        margin-right: auto;
    }
}

.buttonEditBike {
    margin-bottom: 1rem;
    margin-left: auto;

    &:last-of-type {
        margin-right: auto;
    }
}

.buttonsContainer {
    display: flex;
    font-size: 0.875rem;
    width: 100%;
}

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.formContainer {
    color: initial;
    text-align: initial;
    width: 100%;
}

.inputField {
    background-color: $SRAM_50;
    border: none;
    color: $AXS_TEXT_DARK;
    font-size: 1.17rem;
    height: 24px;
    padding-left: 0.5rem;
    width: 100%;

    &:focus {
        background-color: $WHITE;
    }
}

.selectContainer {
    width: 100%;
}

.title {
    color: $AXS_TEXT_DARK;
    font-weight: bold;
    padding-bottom: 0.5rem;
}

/* Have to override default date appearance */
select.inputField {
    appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%20%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%20%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2214px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2014%2012%22%20enable-background%3D%22new%200%200%2014%2012%22%20xml%3Aspace%3D%22preserve%22%3E%20%3Cpolygon%20points%3D%223.862%2C7.931%200%2C4.069%207.725%2C4.069%20%22/%3E%3C/svg%3E);
    cursor: pointer;
    padding-right: 15px;
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .bikeFormContainer {
        height: 100%;
    }
}
