@import '../../../colors.scss';
@import '../../../constants/scss/constants.scss';



.container{
    width: 100%;
    background-color: $WHITE;
    border-radius: 4px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
    color: $BLACK;
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.inputWithUnits {
    border: 1px solid #ededee;
    flex: 1;
    padding-left: 0.5rem;
    width: 100%;
}

.inputContainer{
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
}

.section{
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: $BLACK;  
}

select.inputField {
    border: 1px solid #ededee;
    flex: 1;
    padding: 1px 2px 1px 7px;
    width: 100%;
    appearance: none;
    cursor: pointer;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%20%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%20%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2214px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2014%2012%22%20enable-background%3D%22new%200%200%2014%2012%22%20xml%3Aspace%3D%22preserve%22%3E%20%3Cpolygon%20points%3D%223.862%2C7.931%200%2C4.069%207.725%2C4.069%20%22/%3E%3C/svg%3E);
}

.units {
    align-items: center;
    background-color: $SRAM_200;
    color: $AXS_TEXT_DARK;
    display: flex;
    font-weight: 400;
    justify-content: center;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.usePresetValuesContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.usePresetValuesLabel {
    color: $AXS_TEXT_DARK;
    flex: 1;
    margin-bottom: 0;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
}
.warning{
    color: $SRAM_RED;
    font-weight: 700;
}

@media only screen and (max-device-width: map-get($grid-breakpoints, sm)) {
    .container {
        min-width: none;
    }
}
