@import '../../../colors.scss';
@import '../../../constants/scss/constants.scss';


.container{
    width: 100%;
    background-color: $WHITE;
    border-radius: 4px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
    color: $BLACK;

    .section{
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: flex-start;
        align-items: flex-start;

        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: $BLACK;  
    }

    .chips{
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        font-size: 12px;
        font-weight: 400px;
        line-height: 25px;

        &.selected{
            background-color: $SRAM_RED;
            border: 1px solid $SRAM_RED;
            color: white;
        }
    }
}

.parentContainer{
    width: auto;
    min-width: none;
}

@media only screen and (max-device-width: map-get($grid-breakpoints, lg)) {

.parentContainer{
    max-width: 440px;
}
}


@media only screen and (max-device-width: map-get($grid-breakpoints, sm)) {
    .container {
        min-width: none;
    }
}
