$AXS_TEXT_DARK: #333333;
$AXS_TEXT_DARK_BLUE: #1413FF;
$AXS_TEXT_LIGHT_GREY: #7B7C7F;
$AXS_TEXT_GREY: #58595B;
$AXS_WEB_BG: #F4F4F9;
$BLACK: #000000;
$BLUE_CHALK: #4285F4;
$BLUE_LIGHT: #2666ff;
$BLUE_MILD: #135ACE;
$RED_CHALK: #E51937;
$GREEN_CHALK: #34A853;
$GREEN_FOLAGE: #008000;
$GREEN_LIGHT: #16A64B;
$OPAQUE_LIGHTEST: #0000001A;
$OPAQUE_LIGHT: #00000030;
$OPAQUE: #00000033;
$OPAQUE_DARK: #00000080;
$ORANGE: #FF6639;
$PINK: #E81E79;
$PINK_BABY: #e5719b;
$PINK_DARK: #A8123F;
$PINK_LIGHT: #DE3070;
$PINK_RED: #E1003A;
$RED: #FF0000;
$RED_LIGHT: #CF0A2C;
$SRAM_900: #202124;
$SRAM_800: #3C4043;
$SRAM_700: #5f6368;
$SRAM_600: #80868B;
$SRAM_500: #9AA0A6;
$SRAM_400: #BBBFC4;
$SRAM_300: #DADCE0;
$SRAM_200: #E8EAED;
$SRAM_100: #F1F3F4;
$SRAM_50: #F8F9FA;
$SRAM_RED: #E51937;
$TRANSPARENT: #00000000;
$WHITE: #FFFFFF;
$YELLOW: #FFCC3F;
$YELLOW_LIGHT: #FEC843;
$YELLOW_RIPE_LEMON: #EEE30F;
