.buttonsContainer {
    align-items: center;
    display: flex;
    font-size: 0.875rem;
    justify-content: space-around;
    margin-top: 2rem;
    padding: 1rem;
    width: 100%;
}

.saveButton {
    margin-left: 1rem;
    width: 125px;
}
