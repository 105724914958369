.fadeEnter {
    opacity: 0.01;
}

.fadeEnterActive {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fadeExit {
    left: 0;
    opacity: 0;
    position: absolute !important;
    top: 0;
}
