@import '../../index.scss';

.alertBadgeContainer {
    height: 2rem;
    width: 2rem;
    text-align: center;
}

.alertBadgeImage {
    height: 1rem;
    width: 1rem;
}

.buttonHover {
    &:hover {
        background-color: #3C4043;
    }
}

.dropdownStyle {
    left: 50%;
    transform: translateX(-50%);
};

.logo {
    color: black;
}

.menuItem {
    display: flex;
}

.menuItemButton {
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;

    &:hover {
        > span {
            border-color: #e51937;
        }
    }
}

.plus {
    @extend .flex;

    align-items: center;
    border: 1px solid $AXS_TEXT_LIGHT_GREY;
    border-radius: 50%;
    font-size: 2rem;
    font-weight: 800;
    height: 2rem;
    justify-content: center;
    width: 2rem;
}
