@import '../../../colors';

.clearButton {
    background-color: $TRANSPARENT;
    border: none;
    color: $PINK_BABY;
    font-size: 0.8rem;

    &:focus {
        outline: none;
    }
}

.createBike {
    color: $AXS_TEXT_DARK_BLUE;
    font-size: 0.8rem;

    &:hover {
        color: $AXS_TEXT_DARK_BLUE;
        text-decoration: none;
    }
}

.dateFilterDropdown {
    width: 320px;
}

.dateRangePicker {
    font-weight: normal;
    text-align: right;
    width: 50px;
}

.filterButtons {
    background-color: $TRANSPARENT;
    border: none;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.filterOptions {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
}

.filterOptionsBadge {
    align-items: center;
    display: flex;
    height: 70px;
    justify-content: space-evenly;
}

.selectView {
    display: flex;
    flex-direction: column;
}

.rotateIcon {
    transform: rotate(180deg);
}

.viewButton {
    background-color: $TRANSPARENT;
    border: none;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}
