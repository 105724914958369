@import '../../../index.scss';

.appContainer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 1rem;
    margin-bottom: rem;
    width: 100%;
}

.appNotificationImageContainer {
    height: 125px;
    padding: 0.5rem;
}

.appNotificationImage {
    max-height: 100%;
    max-width: 100%;
    width: auto;
}

.appStoreContainer {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-around;
}

.appStoreImage {
    height: 100%;
    width: auto;
}

.appStoreLink {
    height: 40px;
    margin-top: 1rem;
    text-align: center;
}

.axsAppImage {
    max-height: 100%;
    max-width: 100%;
    width: auto;
}

.axsAppImageContainer {
    height: 170px;
    padding: 0.5rem;
}

.circle {
    align-items: center;
    align-self: baseline;
    border: 1px solid $AXS_TEXT_DARK;
    border-radius: 50%;
    display: flex;
    font-size: 1.2rem;
    height: 2rem;
    justify-content: center;
    min-height: 2rem;
    min-width: 2rem;
    text-align: center;
    width: 2rem;
}

.container {
    background-color: $WHITE;
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    margin: 1rem 0 2rem 0;
}

.content {
    padding: 2rem;
}

.finePrint {
    padding-top: 2rem;
}

.guideContainer {
    align-items: center;
    display: flex;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.guideContent {
    border-bottom: 1px solid $AXS_TEXT_LIGHT_GREY;
    margin-left: 1rem;
    // IE FIX
    min-width: 1px;
}

.header {
    color: $AXS_TEXT_DARK;
    font-size: 1.75rem;
    font-weight: 700;
}

.imageContainer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    width: 100%;
}

.subHeader {
    color: $AXS_TEXT_DARK;
    font-size: 18px;
    font-weight: 700;
}

.text {
    color: $AXS_TEXT_GREY;
    font-size: 1.17rem;
    font-weight: 300;
    padding-top: 1rem;
}
