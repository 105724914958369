@import '../../../colors.scss';

.appScreens {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
    padding-top: 2rem;
    max-width: 600px;
    width: 100%;
}

.appScreenImage {
    height: auto;
    width: 100%;
}

.appSreenImageContainer {
    &:first-child {
        padding-right: 0.5rem;
    }

    &:last-child {
        padding-left: 0.5rem;
    }
}

.appStoresContainer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 2rem;
    width: 100%;
}

.appStoreImage {
    height: 100%;
    width: auto;
}

.appStoreLink {
    height: 40px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.axsAppLogo {
    height: auto;
    width: 100%;
}

.container {
    background-color: $WHITE;
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    margin: 1rem 0 2rem 0;
}

.content {
    padding: 2rem;
}

.finePrint {
    color: $AXS_TEXT_GREY;
    font-size: .75rem;
    padding-top: 2rem;
}

.header {
    color: $AXS_TEXT_DARK;
    font-size: 1.75rem;
    font-weight: 700;
}

.headerContainer {
    border-bottom: 1px solid $AXS_TEXT_LIGHT_GREY;
    padding-bottom: 1.5rem;
}

.link {
    color: $BLUE_MILD;
}

.notificationImage {
    height: auto;
    padding: 0 4rem;
    width: 100%;
}

.subHeader {
    color: $AXS_TEXT_DARK;
    font-size: 1.33rem;
    font-weight: 700;
    padding-bottom: 1rem;
    padding-top: 2rem;
}

.text {
    color: $AXS_TEXT_GREY;
    font-size: 1.17rem;
    font-weight: 300;
}
