@import '../../colors.scss';

.appStoresContainer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 2rem;
    width: 100%;
}

.appStoreImage {
    height: 100%;
    width: auto;
}

.appStoreLink {
    height: 40px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.container {
    display: flex;
    flex-direction: column;
    background-color: $WHITE;
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    margin: 1rem 0 1rem 0;
}

.content {
    padding: 2rem;
}

.header {
    color: $AXS_TEXT_DARK;
    font-size: 1.17rem;
    font-weight: 700;
}

.imageContainer {
    align-items: center;
    background-color: $BLACK;
    display: flex;
    justify-content: center;
    margin: auto;
    height: 4rem;
    width: 100%;
}

.logo {
    height: 2.75rem;
}

.text {
    font-size: 1.17rem;
    font-weight: 300;
}
