@import '../../../../colors';

.activeBadge {
    background-color: $RED_CHALK;
    background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%20%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%20%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2214px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2014%2012%22%20enable-background%3D%22new%200%200%2014%2012%22%20xml%3Aspace%3D%22preserve%22%3E%20%3Cpolygon%20fill%3D%22%23ffffff%22%20points%3D%223.862%2C7.931%200%2C4.069%207.725%2C4.069%20%22%2F%3E%3C%2Fsvg%3E);
    border: 1px solid $RED_CHALK;
    color: $WHITE;
}

.backDrop {
    height: 100vh;
    left: 0px;
    position: fixed;
    top: 0px;
    width: 100vw;
    z-index: 0;
}

.badge {
    appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    border-radius: 2rem;
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1.5rem;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.closeButton {
    background-color: $TRANSPARENT;
    border: none;
    color: $AXS_TEXT_GREY;
    font-weight: bold;
    padding: 0;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.container {
    align-items: center;
    display: flex;
    height: 100%;
    overflow: visible;
    position: relative;
}

.contentHeader {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.dropdownContainer {
    background-color: $WHITE;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    min-width: 160px;
    overflow: auto;
    padding: 0.5rem;
    position: absolute;
    right: 0;
    top: 70%;
    z-index: 1;
}

.label {
    font-weight: bold;
}

.inActiveBadge {
    background-color: $TRANSPARENT;
    background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%20%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%20%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2214px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2014%2012%22%20enable-background%3D%22new%200%200%2014%2012%22%20xml%3Aspace%3D%22preserve%22%3E%20%3Cpolygon%20points%3D%223.862%2C7.931%200%2C4.069%207.725%2C4.069%20%22/%3E%3C/svg%3E);
    border: 1px solid $BLACK;
}
