@import '../../../colors';

.container {
    display: flex;
    justify-content: space-between;
}

.label {
    font-weight: bold;
}

.selectedRange {
    color: $PINK_LIGHT;
    text-align: center;
}
