@import '../../constants/scss/constants.scss';
@import '../../index.scss';


.container {
    max-width: 100dvw;
    min-height: 100dvh;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 0;
    background-color: $BLACK;
    overflow: auto;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 6rem;
    padding: 1rem;
    background-color: $BLACK;
    padding-top: 2rem;



    .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 2.5rem;
        padding: 0;
        color: $WHITE;
        font: 'Roboto', sans-serif;
        font-weight: 500;
        line-height: 20px;
    }
}

.nav{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: $BLACK;
    gap: 1rem;

    .backButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        color: $WHITE;
        cursor: pointer;
    }

}

.section{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 0;
    


    .sectionTitle {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: fit-content;
        font-size: .8rem;
        padding: 0 0.5rem;
        color: $SRAM_600;
        font: 'Roboto', sans-serif;
        line-height: 20px;
        padding: .7rem;
        padding-inline-start: 20px;
        border-top: 1px solid #1C1C1E;
        border-bottom: 1px solid #1C1C1E;
    }
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    background-color: #1C1C1E;
    border-bottom: 1px solid $SRAM_600;
    padding: 10px 20px;
    cursor: pointer;
    color: $WHITE;
    z-index: 1;

    .rowTitleContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        color:  $SRAM_600;
        width: 100%;
    }

    select{
        border: none;
    }

    .rowTitle{
        font-size: 1rem;
        color: $WHITE;
    }

    .arrow{
        color: white;
        padding: 0;
    }

    .rowEnd{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;
        color:  $SRAM_600;
    }
}

.rowDesc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: fit-content;
    background-color: #1C1C1E;
    cursor: pointer;
    color: $WHITE;
    z-index: 1;
    font-size: .8rem;
    color: $SRAM_600;
}

.inputContainer{
    width: 100%;
    background-color: #1C1C1E;
    display: flex;
    flex-direction: column;
    align-items: end;
    border-radius: 2px;
    padding: 8px 20px;
    gap: 8px;

    .button{
        border: none;
        border-radius: 2px;
        color: $WHITE;
        font-size: 1.1rem;
        padding: 8px;
        width: 50%;
        margin-top: 1rem;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.saveBtnContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2px;
    padding: 8px 20px;
    gap: 8px;

    .button{
        border: none;
        border-radius: 2px;
        font-size: 1.1rem;
        padding: 8px;
        width: 75%;
        margin-top: 1rem;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.searchInput{
    background-color:#1C1C1E;
    border: none;
    border-radius: 2px;
    color: $WHITE;
    font-size: 1.1rem;
    font-weight: 300;
    padding: 8px;
    width: 100%;
    padding-inline-start: .5rem;
    border: 1px solid $WHITE;
} 

.closeButton {
    background: url(../../assets/icons/grayX.svg) no-repeat;
    border: none;
    height: 20px;
    margin: 1rem;
    object-fit: cover;
    width: 20px;

    &:focus {
        outline: none;
    }

    &:hover {
        background: url(../../assets/icons/grayXDark.svg) no-repeat;
    }
}
