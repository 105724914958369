@import '../index.scss';

.container {
    display: flex;
    flex-direction: column;
    min-height: inherit;
}

.content {
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 1px;
}
