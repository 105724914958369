@import '../../../../index.scss';

.container {
    padding: 1rem 2rem;
}

.tyrewiz {
    width: 100%;

    &:not(:last-child) {
        border-bottom: 1px solid $SRAM_600;
    }
}
