.buttonContainer {
    display: flex;
    justify-content: space-around;
    margin-top: auto;
    padding-top: 1rem;
    width: 100%;
}

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.content {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
}
