.container {
    position: relative;
}

.alertBadge {
    height: 20%;
    position: absolute;
    right: 0;
    top: 0;
}
