@import '../../index.scss';

.bikeComputerToAXSImage {
    display: block;
    height: auto;
    margin: auto;
    max-height: 100;
    max-width: 100%;
    padding-top: 1rem;
}

.header {
    color: $AXS_TEXT_DARK;
    font-size: 18px;
    font-weight: 700;
}

.imageContainer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    width: 100%;
}

.linkedAppsButton {
    padding-bottom: 1rem;
    padding-top: 1rem;
    text-align: center;
}

.text {
    color: $AXS_TEXT_GREY;
    font-size: 1.17rem;
    font-weight: 300;
    padding-top: 1rem;
}
