@import '../../../../../colors.scss';

.container {
    text-align: center;
}

.lthrValue {
    background-color: $TRANSPARENT;
    border: none;
    color: $AXS_TEXT_DARK_BLUE;
    font-weight: bold;
    padding: 0px;

    &:focus {
        outline: 0;
    }
}
