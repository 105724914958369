@import '../../constants/scss/constants.scss';
@import '../../colors.scss';

.container {
    margin: 0 auto;
    max-width: map-get($grid-breakpoints, xl);
    padding: 4rem 0.5rem 0 0.5rem;
    width: 100%;
}

.content {
    background-color: $WHITE;
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    margin-top: 2rem;
}

.contentContainer {
    padding-top: 0;
}

.header {
    align-items: center;
    border-bottom: 1px solid $SRAM_600;
    display: flex;
    flex-wrap: wrap;
    font-size: 2rem;
    font-weight: 500;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.noComponentFoundText {
    color: $SRAM_700;
    font-size: 1.5rem;
    margin-left: 1.5rem;
}
