@import '../../../../constants/scss/constants.scss';
@import '../../../../index.scss';

.clearButton {
    color: $PINK_BABY;
    font-size: 0.8rem;
}

.createBike {
    color: $AXS_TEXT_DARK_BLUE;
    font-size: 0.8rem;

    &:hover {
        color: $AXS_TEXT_DARK_BLUE;
        text-decoration: none;
    }
}

.dateFilterDropdown {
    margin: auto;
    min-width: 250px;
}

.distanceDurationDropdown {
    margin: auto;
    padding: 0 2rem;
    width: 350px;
}

.sortIcon {
    color: $SRAM_800;
}

.tableCell {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.tableCellTitle {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.tableHeader {
    background-color: $SRAM_400;
    border-right: 2px solid $WHITE;
    color: $SRAM_800;
    font-size: 1rem !important;
    padding: 0.75rem 0.5rem;
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
    .distanceDurationDropdown {
        max-width: 300px;;
    }
}
