@import '../../../constants/scss/constants.scss';
@import '../../../index.scss';

.chainLengthInfo {
    width: 100%;
}

.chainLengthResult {
    padding-top: 1rem;
}

.container {
    display: flex;
    flex-direction: column;
}

.content {
    color: initial;
    text-align: left;
}

.desktopContainer {
    padding-left: 2rem;
    text-align: center;
}

.footer {
    text-align: center;
}

.footerNote {
    font-size: 12px;
    max-width: 320px;
    text-align: left;
}

.header {
    display: flex;
    flex-direction: column;
}

.image {
    margin: 1rem auto;
    height: 225px;
    max-width: 320px;
}

.imageContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.imageTitle {
    font-size: 1.25rem;
    font-weight: 500;
    text-align: left;
}

.info {
    display: flex;
    flex-direction: row;
}

.infoIcon {
    color: $SRAM_400;
}

.label {
    white-space: nowrap;
}

.link {
    font-size: 0.75rem;
    font-weight: 500;
    margin: 1rem 0;
    padding: 0.5rem;
}

.modalContainer {
    padding: 1rem 2rem;
    max-width: map-get($grid-breakpoints, md);
    min-height: 30vh;
}

.popover {
    background-color: $SRAM_300;
    max-width: 270px;
    padding: 1rem;
}

.selectedBike {
    font-size: 1.5rem;
    font-weight: 500;
}

.selectedChainstay {
    font-size: 1rem;
}

.row {
    border-bottom: 1px solid $SRAM_500;
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    font-weight: 500;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
    &:hover {
        background-color: $SRAM_100;
    }
}

.rowSelected {
background-color: $SRAM_400;
}

.value {
    white-space: nowrap;
}

.warning {
    font-size: 10px;
    font-weight: 400;
    display: inline;
}

.warningBold {
    font-size: 0.8rem;
    font-weight: 700;
    display: inline;
}

.warningSign {
    background-color: $SRAM_800;
    border-radius: 2rem;
    color: $YELLOW_RIPE_LEMON;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0.7rem auto;
    max-width: 12rem;
}

.warningWrapper {
    max-width: 420px;
    margin: 0 auto;
    line-height: 12px;
}

.videoImage {
    width: 100%;
}

.videoContainerLabel {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 16px;
}

.videoTitle {
    font-size: 14px;
    font-weight: 500;
    padding-top: 0.3rem;
}

@media (max-width: map-get($grid-breakpoints, sm)) {
    .modalContainer {
        padding: 0;
    }

    .row {
        padding: 1rem 0.5rem;
    }

    .warningWrapper {
        max-width: 280px;
        margin: 0 auto;
    }
}

@media (max-width: map-get($grid-breakpoints, xs)) {
    .image {
        height: auto;
        max-width: 270px;
    }
}
