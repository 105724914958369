@import '../../constants/scss/constants.scss';

.container {
    display: flex;
    flex-direction: column;
    min-height: inherit;
}

.contentContainer {
    flex: auto;
    flex-direction: column;
    min-height: 1px;
}

.content {
    margin: auto;
    max-width: map-get($grid-breakpoints, sm);
    padding: 3rem;
    width: 100%;
}

.help {
    font-weight: bold;
    margin-bottom: 1rem;
}

.notes {
    font-size: 1.125rem;
    margin-top: 2rem;
}

.subtitle {
    font-size: 1.875rem;
}

.title {
    font-size: 4.25rem;
    font-weight: bold;
}
