@import '../../../colors.scss';

.container {
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    position: relative;
    margin-bottom: 1rem;
}

.buildButton {
    line-height: 1.33rem;
    padding: 1rem 2rem 1rem 2rem;
}

.desktopBottomBar {
    background-color: $WHITE;
    padding: 1.25rem;
    justify-content: flex-end;
}

.mobileLink {
    display: block;
    height: 100%;
    width: 100%
}

.mobileLinkContainer {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}
