@import '../../../colors.scss';

.accountLogoContainer {
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
}

.backDrop {
    background-color: $SRAM_RED;
    height: 7px;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.buttonLogin {
    align-items: center;
    background-color: $SRAM_RED;
    color: $WHITE;
    display: flex;
    margin-right: 1rem;
    text-align: center;
    text-transform: uppercase;

    &:hover {
        color: $WHITE;
        text-decoration: none;
    }
}

.messagesContainer {
    display: flex;
    width: 86px;
    height: 100%;
    position: relative;
    justify-content: center;
}

.messagesLink {
    background-color: $TRANSPARENT;
    border: none;
    border-radius: 3px;
    color: $WHITE;
    display: flex;
    font-size: 1.33rem;
    font-weight: 700;
    justify-content: center;
    outline: none !important;
    padding: 0.25rem calc(1rem - 7.5px);
    text-align: center;
    align-self: center;

    &:hover {
        background-color: #3C4043;
    }
}

.navLogos {
    align-items: center;
    display: flex;
    flex: 2;
    height: 100%;
    justify-content: space-around;
}
