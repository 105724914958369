@import '../../../colors.scss';
@import '../../../index.scss';

.header {
    color: $AXS_TEXT_DARK;
    font-size: 1.33rem;
    font-weight: 500;
}

@mixin inputs {
    background-color: $TRANSPARENT;
    border: 1px solid $SRAM_200;
    border-radius: 2px;
    color: $AXS_TEXT_DARK;
    font-size: 1.17rem;
    font-weight: 300;
    height: 100%;
    padding: 1px;
    padding-left: 0.5rem;
    width: 100%;
}

.datePickerInput {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 1px solid #ededee !important;
    border-radius: 2px !important;
    box-sizing: inherit !important;
    color: #333333 !important;
    font-size: 1.17rem !important;
    font-weight: 300 !important;
    height: 100% !important;
    padding: 1px !important;
    padding-left: 0.5rem !important;
    width: 100% !important;
}



.datePickerRoot {
    line-height: inherit !important;
    width: 100%;
    height: 100%;
}

.datePickerOverride {
    width: 100%;

    :global(.MuiFormControl-root, .MuiIconButton-root) {
        background-color: $TRANSPARENT;
        height: 100%;

        &:focus {
            outline: 0;
        }

        &:hover {
            outline: 0;
        }
    }
}

.header {
    color: $SRAM_800;
    font-size: 1.33rem;
    font-weight: 500;
}

.imagePickerLogo {
    cursor: pointer;
    font-size: 0;
    height: 20px;
    opacity: 0;
    position: absolute;
}

.imagePickerLogoContainer {
    background-color: $SRAM_300;
    border-radius: 50%;
    padding: 0.2rem;
    bottom: 0;
    position: absolute;
    right: 0;
}

.inputField {
   @include inputs;
}

.profileImage {
    border: 3px solid $SRAM_RED;
    border-radius: 50%;
    height: 6rem;
    margin-bottom: 1rem;
    overflow: hidden;
    width: 6rem;
}

.profileImageContainer {
    height: 6rem;
    position: relative;
    width: 6rem;
}

.subTitle {
    color: $AXS_TEXT_DARK;
    font-size: 14px;
    overflow: hidden;
    word-break: break-word;
}

.displayStats {
    color: $AXS_TEXT_DARK;
    font-size: 1.17rem;
    font-weight: 300;
    padding: 1px;
    padding-left: 0.5rem;
}

.themeProviderRoot {
    width: 100%
}

.title {
    color: $AXS_TEXT_DARK;
    font-size: 18px;
}

.units {
    align-items: center;
    background-color: $SRAM_300;
    color: $AXS_TEXT_DARK;
    display: flex;
    font-weight: 400;
    justify-content: center;
    margin-left: 1rem;
    min-width: 50px;
}

/* Have to override default date appearance */
select.inputField {
    appearance: none;
    cursor: pointer;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%20%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%20%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2214px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2014%2012%22%20enable-background%3D%22new%200%200%2014%2012%22%20xml%3Aspace%3D%22preserve%22%3E%20%3Cpolygon%20points%3D%223.862%2C7.931%200%2C4.069%207.725%2C4.069%20%22/%3E%3C/svg%3E);
}
