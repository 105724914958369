.container {
    font-size: 1.25rem;
    text-align: center;
}

.description {
    padding: 0 5rem;
}

.descriptionTitle {
    padding-bottom: 1.5rem;
}

.subtitle {
    font-weight: bold;
    padding: 2rem;
}
