@import '../../index.scss';
@import '../../colors';

.activeTabBar {
    background-color: $SRAM_RED;
    height: 2px;
    position: absolute;
    top: 0;
    transition: left 0.25s ease-in;
}

.container {
    @extend .flex;
    @extend .sticky;

    align-items: center;
    background-color: #e0e0e0;
    bottom: -1px;
    height: calc(51px + env(safe-area-inset-bottom));
    justify-content: space-around;
    padding-bottom: calc(1px + env(safe-area-inset-bottom));
    z-index: 100;
}

.linkStyle {
    @extend .flex;

    align-items: flex-end;
    border-bottom: 1px solid #7b7c7f;
    color: #7B7C7F;
    font-size: 1rem;
    justify-content: space-between;
    min-height: 0.5rem;
    padding: 1rem 0.5rem;

    &:hover {
        color: $SRAM_RED;
        text-decoration: none;
    }

    &:last-of-type {
        border-bottom: none;
    }
}

.loginLink {
    background-color: #00000000;
    color: $SRAM_RED;
    font-weight: initial;
}

.plus {
    @extend .flex;

    align-items: center;
    border: 1px solid #7b7c7f;
    border-radius: 50%;
    font-size: 2rem;
    font-weight: 800;
    height: 2rem;
    justify-content: center;
    width: 2rem;

    &:hover {
        color: $SRAM_RED;
        text-decoration: none;
    }
}

.profileImageActive {
    background-color: darken($SRAM_RED, 15%);
}

.profileImageButton {
    background-color: #00000000;
    border: none;
    outline: none;
    padding: 0;
    text-decoration: none;

    &:focus {
        outline: none;
    }

    &:hover {
        outline: none;
    }
}
