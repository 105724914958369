@import '../../index.scss';
@import '../../constants/scss/constants.scss';


.container {
    max-width: 100dvw;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 0;
    background-color: $SRAM_900;
    overflow: hidden;
    z-index: 1;
}

.modalContainer{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 44px;
    padding: 0;
    background-color: $BLACK;
    padding: .7rem;

    .helpBtn {
        position: absolute;
        right: .7rem;
        top: .7rem;
    }
}

.helpButton {
    background: none;
    border: none;
    color: $WHITE;

    &:focus {
        outline: none;
    }
}

.title {
    font-size: 1.2rem;
    padding: 0 0.5rem;
    color: $WHITE;
    font: 'Roboto', sans-serif;
    line-height: 20px;
}


.description {
    font-size: 14px;
    color: $WHITE;
}

.mobileContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 20px 16px 20px;
    width: 100%;
    background-color: $SRAM_900;
}

.infoContainer{
    padding: 1rem 1rem 0 1rem;
    width: 100%;
    display: flex;
    gap: 1rem;

}

.infoCardTitle{
    position: relative;
    font-size: 14px;
    padding: 0 0.5rem;
    color: $SRAM_500;
    font: 'Roboto', sans-serif;
    line-height: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

.infoCard{
    position: relative;
    background-color: $BLACK;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    height: 91px;
    max-height: 100px;
}

.infoCardText{
    font-size: 20px;
    padding: 0 0.5rem;
    color:$WHITE;
    font-weight: 700;
    height: 100%;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.infoButton{
    position: absolute;
    top: .2rem;
    right: .2rem;
    color: $WHITE;
    padding: 0;
}


.warningWrapper {
    font-size: 10px;
    font-weight: 400;
    max-width: 420px;
    margin: 0 auto;
    color: $YELLOW;
    padding: 1rem;
}

.warning {
    font-size: 10px;
    font-weight: 400;
    display: inline;
}

.warningBold {
    font-size: 10px;
    font-weight: 700;
    display: inline;
}

.videoContainerLabel {
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 16px;
    color: $WHITE;
    background-color: $BLACK;
    width: 100%;
    padding: 1rem;
}

.videoImage {
    width: 100%;
    color:$WHITE;
}

.videoTitle {
    font-size: 14px;
    font-weight: 500;
    padding-top: 0.3rem;
}

.videoImage {
    width: 100%;
    border-radius: 10px;
    height: 128px;
    object-fit: cover;
}

.autoSizer{
    width: '100%';
    height: '100%';
}

.videoImageContainer{
    border-radius: 10px;
    // height: 128px;
}
