@import '../../colors.scss';

.container {
    align-items: center;
    align-content: center;
    background-color: $BLACK;
    display: flex;
    height: 4.5rem;
    justify-content: space-between;
    min-height: 4.5rem;
    width: 100%;
    z-index: 20;
}

.logoContainer {
    display: flex;
    flex: 1;
}
