.button {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.buttonContainer {
    margin-top: auto;
    padding-top: 1rem;
}

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
