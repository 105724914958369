@import '../../../index.scss';

.container {
    background-color: $SRAM_100;
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.header {
    border: none;
    outline: none;
    padding: 0px;
    text-align: start;
    width: 100%;
}

.headerContainer {
    align-items: center;
    background-color: $SRAM_400;
    display: flex;
    padding: 1rem;
    padding-right: 1.5rem;
}

.icon {
    background-color: $SRAM_RED;
    border-radius: 3px;
    display: inline-block;
    height: 2.5rem;
    margin-right: 1rem;
    width: 2.5rem;
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
    width: 100%;
}
