@import '../../../constants/scss/constants.scss';
@import '../../../colors.scss';

.addComponentButton {
    background-image: linear-gradient($PINK, $SRAM_RED 50%);
    border: none;
    border-radius: 4px;
    color: $WHITE;
    font-weight: bold;
    margin: auto;
    padding: 1rem 2rem;
    text-transform: uppercase;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.addComponentButtonContainer {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.bikeColumn {
    padding: 0;
    width: 45%;
}

.bikeButtonsContainer {
    display: flex;
    font-size: 0.875rem;
    padding-top: 2rem;
    width: 100%;
}

.bikeFormContainer {
    color: $BLACK;
}

.bikeInput {
    appearance: none;
    background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%20%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%20%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2214px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2014%2012%22%20enable-background%3D%22new%200%200%2014%2012%22%20xml%3Aspace%3D%22preserve%22%3E%20%3Cpolygon%20points%3D%223.862%2C7.931%200%2C4.069%207.725%2C4.069%20%22/%3E%3C/svg%3E);
    background-position: right 50%;
    background-repeat: no-repeat;
    background-size: 30px;
    border: 1px solid $SRAM_200;
    color: $BLACK;
    cursor: pointer;
    font-size: 1.17rem;
    height: 32px;
    padding-left: 0.5rem;
    width: 220px;

    &:focus {
        background-color: $WHITE;
    }
}

.buttonEditBike {
    margin-bottom: 1rem;
    margin-left: auto;

    &:last-of-type {
        margin-right: auto;
    }
}

.cancelButton {
    background-color: $TRANSPARENT;
    border: none;
}

.cancelIcon {
    color: $SRAM_RED;
}

.checkIcon {
    color: $GREEN_CHALK;
    margin-right: 1rem;
}

.column {
    color: $SRAM_900;
    font-size: 1rem;
    padding-left: 1rem;
    text-align: left;
}

.deleteButtonsContainer {
    display: flex;
    justify-content: space-around;
    margin-top: auto;
    padding-top: 1rem;
    width: 100%;
}

.deleteColumn {
    text-align: center;
}

.divider {
    border-bottom: 1px solid $SRAM_400;
    padding: 1rem;
}

.doneButton {
    text-align: right;
}

.errorMessage {
    color: $RED;
    display: flex;
    font-size: 1rem;
    justify-content: left;
    padding-top: 1rem;
}

.form {
    padding: 2rem 0;
}

.header {
    text-align: center;
}

.inputLabel {
    color: $BLACK;
    font-size: 1rem;
    font-weight: 500;
    padding-bottom: 0.5rem;
    text-align: left;
}

.inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.link {
    color: $BLACK;
}

.optional {
    text-transform: lowercase;
}

.productRegisterContainer {
    color: $BLACK;
    padding: 2rem;
    padding-bottom: 0;
}

.productRegisterHeader {
    text-align: left;
}

.productRegisterTitle {
    font-size: 1.5rem;
    font-weight: 1000;
}

.registeredContent {
    color: $BLACK;
    padding: 2rem;
    padding-top: 0;
}

.row {
    height: 40px;
    width: 100%;

    &:nth-of-type(even) {
        background-color: $SRAM_50;
    }

    &:nth-of-type(odd) {
        background-color: $SRAM_100;
    }
}

.serial {
    display: flex;
    flex-direction: column;
}

.serialColumn {
    width: 40%;
}

.serialInput {
    border: 1px solid $SRAM_200;
    box-shadow: none;
    color: $AXS_TEXT_DARK;
    font-size: 1.17rem;
    height: 32px;
    padding-left: 0.5rem;
    width: 220px;

    &:focus {
        background-color: $WHITE;
    }
}

.serialNumbersTable {
    margin: 2rem 0 3rem 0;
    width: 100%;
}

.submitButton {
    text-align: right;
}

.tableHeader {
    color: $BLACK;
    font-size: 1rem;
    text-align: left;
}

.tableHeaderColumn {
    font-weight: 500;
    padding-left: 1rem;
}
