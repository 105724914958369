@import '../../../colors.scss';

.container {
    background-color: $WHITE;
}

.content {
    justify-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 492px;
    padding: 2rem;
    padding-top: 0;
    width: 100%;
}

.headerContainer {
    padding: 2rem;
    width: 100%;
}

.indicator {
    color: $SRAM_RED;
    height: auto;
    transform: rotate(-90deg);
    transition: transform 0.05s;
    width: 10px;
}

.labelContainer {
    align-items: center;
    background-color: $WHITE;
    border: none;
    display: flex;
    justify-content: space-between;
    outline: none;
    width: 100%;
}

.labelHeaderContainer {
    color: $AXS_TEXT_DARK;
    flex: 1;
    font-size: 1.33rem;
    font-weight: 500;
    text-transform: uppercase;
}

.rotateIcon {
    transform: rotate(90deg);
}

// padding-bottom here to fix rendering error with border
.topBorder {
    border-bottom: 1px solid $SRAM_400;
    margin: 0 2rem;
    padding-bottom: 1px;
}
