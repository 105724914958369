@import '../../../../colors.scss';

.modalButton {
    background-color: $TRANSPARENT;
    border: none;
    color: $BLUE_MILD;
    padding: 0px;

    &:focus {
        outline: 0;
    }
}
