@import '../../../colors.scss';

.cardTitle {
    font-weight: bold;
    text-transform: uppercase;
}

.container {
    border-top: 1px solid $SRAM_400;
    font-size: 1.17rem;
    padding: 1rem;
    padding-bottom: 2rem;
}

.content {
    color: $AXS_TEXT_GREY;
    text-align: center;
}

.guideButton {
    background-image: linear-gradient($PINK, $SRAM_RED 50%);
    border: none;
    border-radius: 4px;
    color: $WHITE;
    font-weight: bold;
    padding: 1rem 2rem;
    text-transform: uppercase;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.guideButtonContainer {
    padding: 2rem 0;
}
