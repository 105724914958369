@import '../../../../index.scss';

.axis {
    text {
        font-size: 1rem;
    }
}

.chart {
    overflow: visible;
}

.chartContainer {
    height: calc(100vh - 13rem);
    max-height: 400px;
    overflow: hidden;
    padding-bottom: 1.675rem;
    padding-left: 3.25rem;
    padding-top: 1rem;
}

.container {
    padding: 0 1rem;
    padding-bottom: 2rem;
}

.editDrivetrain {
    text-align: center;
}

.endTime {
    margin-left: auto;
    padding-top: 5px;
}

.footer {
    padding: 1.5rem 0;
}

.indicator {
    fill: none;
    stroke: $BLACK;
    stroke-width: 3;
}

.mobileTimeScale {
    display: none;
}

.noDataNote {
    align-items: center;
    display: flex;
    font-size: 1.5rem;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
}

.startTime {
    padding-top: 5px;
}

.statistic {
    font-weight: bold;
    margin: 0px 0.5rem;
    min-width: 60px;
    text-align: right;
}

.statisticsContainer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    min-height: 25px;
    padding-right: 30px;
}

.xAxisLabel {
    text-align: center;
}

.yAxisLabel {
    text-align: left;
}

@media (max-width: 500px) {
    .axis {
        text {
            font-size: 0.7rem;
        }
    }

    .chartContainer {
        padding-bottom: 1.29rem;
        padding-left: 2.25rem;
        padding-top: 0.25rem;
    }

    .mobileTimeScale {
        display: flex;
        font-size: 12px;
    }

    .xAxis {
        line {
            display: none;
        }

        text {
            display: none;
        }
    }
}
