@import '../../../colors.scss';

.container {
    align-items: center;
    border-bottom: 1px solid $AXS_TEXT_LIGHT_GREY;
    color: $AXS_TEXT_LIGHT_GREY;
    font-size: 1.17rem;
    font-weight: 300;
    // IE Requires a height to be set for flex to work
    height: 0px;
    margin: 0 1.5rem 0 1.5rem;
    min-height: 65px;

    &:last-of-type {
        border-bottom: none;
    }
}
