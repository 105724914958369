@import '../../constants/scss/constants.scss';
@import '../../colors.scss';
@import '../../index.scss';

.button {
    align-items: center;
    border: none;
    border-radius: 3px;
    display: flex;
    height: 40px;
    justify-content: center;
    min-width: 120px;
    padding: 1rem;
    text-align: center;
    text-decoration: none;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.ContentHeaderContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.container {
    height: 100%;
    margin: 0 auto;
    max-width: 800px;
    padding-bottom: 2rem;
    width: 100%;
}

.content {
    padding-bottom: 2rem;
}

.deleteButton {
    align-items: center;
    background-color: $SRAM_RED;
    border: none;
    border-radius: 3px;
    color: $WHITE;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-left: auto;
    min-width: 120px;
    padding: 1rem;
    text-align: center;
    text-decoration: none;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.footer {
    background-color: $WHITE;
    height: 3rem;
}

.link {
    background-color: $SRAM_300;
    color: $AXS_TEXT_DARK;

    &:hover {
        background-color: $SRAM_400;
        color: $BLACK;
        outline: none;
        text-decoration: none;
    }
}

.modalButtonsContainer {
    display: flex;
    justify-content: space-around;
    margin-top: auto;
    padding-top: 1rem;
    width: 100%;
}

.modalContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.releaseNotesContainer {
    margin-top: 1rem;
}

.releaseNotesTitle {
    color: $SRAM_800;
    font-weight: bold;
}

.serviceTable {
    border-collapse: collapse;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 100%;

    td {
        border: 1px solid $WHITE;
        color: $AXS_TEXT_DARK;
        font-size: 1rem;
        font-weight: 500;
        padding-left: 1rem;
    }

    tr {
        background-color: $SRAM_50;
        height: 40px;
    }
}

.sublabel {
    font-size: 14px;
    font-weight: lighter;
}

.videosContainer {
    display: flex;
    flex-wrap: wrap;
}

.videoContent {
    padding: 0 1rem;
    padding-bottom: 0.5rem;
    width: 50%;
}

.videoImage {
    width: 100%;
}

.videoImageContainer {
    position: relative;
    width: 100%;
}

.videoPlay {
    height: 2rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.videoTitle {
    font-size: 1.17rem;
    font-weight: 700;
    padding-top: 0.3rem;
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .deleteButton {
        background-color: $TRANSPARENT;
        color: $SRAM_RED;
    }

    .link {
        background-color: $TRANSPARENT;
        color: $SRAM_700;

        &:active {
            background-color: $TRANSPARENT;
        }

        &:focus {
            background-color: $TRANSPARENT;
        }

        &:hover {
            background-color: $TRANSPARENT;
        }
    }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
    .videoContent {
        padding: 0 1rem;
        padding-bottom: 0.5rem;
        width: auto;
    }
}
