@import '../../index.scss';

.profileImage {
    /* background color to fix chrome image padding at zoomed views*/
    background-color: $SRAM_RED;
    border: 3px solid $SRAM_RED;
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
}
