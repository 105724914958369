@import '../../constants/scss/constants.scss';
@import '../../index.scss';

.card {
    background-color: $TRANSPARENT;
    border: none;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    display: flex;
    flex-direction: column;
    height: 520px;
    overflow: hidden;
    padding: 0;
    width: 300px;
    max-width: 25rem;
}

.container {
    background-color: #404047;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 5rem 0;
}

.content {
    background-color: $WHITE;
    flex: 1;
    padding: 2rem;
    position: relative;
}

.description {
    text-align: center;
}

.email {
    padding-bottom: 2rem;
    text-align: center;
}

.emailSentContainer {
    display: block;
    height: 4rem;
    margin: 1rem auto;
    width: 4rem;
}

.emailSentErrorIcon {
    height: 100%;
    width: auto;
}

.emailSentSuccessIcon {
    border: 2px solid $GREEN_FOLAGE;
    border-radius: 50%;
    color: $GREEN_FOLAGE;
    font-size: 2.5rem;
    height: 100%;
    text-align: center;
    width: 100%;
}

.headerContainer {
    align-items: center;
    background-color: $SRAM_300;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.image {
    bottom: 0;
    height: auto;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 90%;
}

.imageContainer {
    background-color: $SRAM_RED;
    color: $WHITE;
    height: 4rem;
    margin-bottom: 1rem;
    position: relative;
    width: 4rem;
}

.refreshButton {
    background-color: $SRAM_600;
    border: none;
    color: $WHITE;
    display: inline-block;
    font-weight: 500;
    padding: 2rem;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    &:hover {
        color: $BLACK;
        text-decoration: none;

        >.refreshButtonText {
            color: $WHITE;
            transform: scale(1.05);
            -webkit-transform: scale(1.05);
        }
    }

    &:active {
      outline: none;
    }

    &:focus {
      outline: none;
    }
}

.refreshButtonText {
    display: block;
    transition: 0.2s ease-in-out transform;
    -webkit-transition: 0.2s ease-in-out transform;
}

.resendButton {
    background-color: $TRANSPARENT;
    border: none;
    color: $AXS_TEXT_LIGHT_GREY;
    font-size: 1rem;
    font-weight: 300;
    outline: none;
    padding: 0;
    text-decoration: underline;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        color: $BLUE_CHALK;
    }
}

.resendContainer {
    background-color: $WHITE;
    padding: 1rem;
    text-align: center;
}

.subtitle {
    text-align: center;
}

.tickContainer {
    height: 90px;
    padding: 1rem 0;
    text-align: center;
}

.title {
    color: $BLACK;
    font-size: 1.5rem;
    font-weight: 400;
}

@media (max-width: map-get($grid-breakpoints, sm)) {
    .card {
        border-radius: 0;
        height: calc(100vh - 4.5rem);
        max-width: map-get($grid-breakpoints, sm);
        width: 100%;
    }

    .container {
        justify-content: center;
        padding: 0;
    }

    .headerContainer {
        background-color: $SRAM_50;
    }
}
