@import '../../../colors';

.actionButton {
    background-color: $TRANSPARENT;
    color: $SRAM_600;
    font-size: 1.17rem;
    font-weight: 300;
    height: 100%;
    padding: 0;
    text-align: left;
    width: 100%;

    &:hover {
        color: $SRAM_RED;
    }
}

.button {
    border: none;
    text-decoration: none;
}

.dropdown {
    background-color: $WHITE;
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    min-width: 250px;
    overflow: auto;
    right: 0;
    top: 100%;
}

.mobileButtonRow {
    align-items: center;
    border-bottom: 1px solid $SRAM_600;
    color: $SRAM_600;
    display: flex;
    font-size: 1.17rem;
    font-weight: 300;
    // IE Requires a height to be set for flex to work
    height: 0px;
    min-height: 65px;

    &:last-of-type {
        border-bottom: none;
    }
}

.moreButton {
    background-color: $TRANSPARENT;
}

.moreIcon {
    height: auto;
    width: 2rem;
}
