@import '../../../../index.scss';

.container {
    display: flex;
    font-size: 1.5rem;
    font-weight: bold;
    padding-top: 0.5rem;
    width: 100%;
}

.duration {
    margin-left: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
}

.labelContainer {
    margin-left: 1rem;
    min-width: 125px;
    padding-right: 1rem;
    text-align: right;
    width: 125px;
    word-break: break-word;
}

.noDataNote {
    align-items: center;
    display: flex;
    font-size: 1.5rem;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
}
