@import '../../../colors.scss';

.arrow {
    background: url(../../../assets/icons/rightArrow.svg) no-repeat;
    height: 15px;
    object-fit: cover;
    margin: 1rem;
    width: 10px;
}

.container {
    border-bottom: 1px solid $SRAM_400;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 1rem;

    &:hover {
        text-decoration: none;

        .arrow {
            background: url(../../../assets/icons/rightArrowRed.svg) no-repeat;
        }
    }

    &:last-of-type {
        border-bottom: none;
    }
}

.content {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 100%;
}

.icon {
    height: 50px;
    width: auto;
}

.master {
    background-color: $AXS_TEXT_LIGHT_GREY;
    color: $AXS_TEXT_DARK;
    font-size: 0.65rem;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    text-transform: uppercase;
    width: 50px;
}

.subText {
    color: $SRAM_700;
    font-size: 0.83rem;
    font-weight: 300;
    padding-bottom: 1rem;
}

.text {
    color: $SRAM_700;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: auto;
    margin-top: auto;
    text-transform: uppercase;
}
