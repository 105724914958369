@import '../../../../colors';
@import '../../../../index.scss';

.desktopContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 2rem;
}

.highTemp {
    color: $PINK_RED;
}

.label {
    color: $AXS_TEXT_GREY;
    font-size: 0.875rem;
    font-weight: lighter;
}

.lowTemp {
    color: $BLUE_LIGHT;
}

.row {
    align-items: center;
    display: flex;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    width: 100%;
}

.value {
    color: $AXS_TEXT_DARK;
    font-size: 1.375rem;
    font-weight: bold;
    margin-left: auto;
}

.flexStyle{
    flex: 1
}
