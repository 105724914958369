@import '../../../colors.scss';

.backDrop {
    background-color: $SRAM_RED;
    bottom: 0px;
    height: 7px;
    left: 0px;
    position: absolute;
    width: 100%;
}

.dropdown {
    background-color: $WHITE;
    border-bottom: 9px solid $SRAM_RED;
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    max-height: calc(100vh - 9rem);
    min-width: 250px;
    overflow: auto;
    top: 100%;
}
