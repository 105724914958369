@import '../../../../colors';
@import '../../../../constants/scss/constants.scss';

.backDrop {
    height: 100vh;
    left: 0px;
    position: fixed;
    top: 0px;
    width: 100vw;
    z-index: 0;
}

.button {
    background-color: $TRANSPARENT;
    border: none;
    padding: 0;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.closeButton {
    background-color: $TRANSPARENT;
    border: none;
    color: $AXS_TEXT_GREY;
    font-weight: bold;
    padding: 0;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.container {
    align-items: center;
    height: 100%;
    overflow: visible;
}

.contentHeader {
    display: flex;
    justify-content: space-between;
}

.dropdownContainer {
    background-color: $WHITE;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    min-width: 180px;
    overflow: auto;
    padding: 0.5rem;
    position: absolute;
    z-index: 16;
}

.filterIcon {
    color: $SRAM_800;
}

.label {
    font-weight: bold;
}
