@import '../../colors.scss';
@import '../../constants/scss/constants.scss';

.container {
    color: $AXS_TEXT_LIGHT_GREY;
    font-size: 1.17rem;
    font-weight: 300;
    min-width: 1px;
    padding: 0;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.disclaimer {
    color: $AXS_TEXT_DARK;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-inline: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
}

.header {
    border-bottom: 1px solid $AXS_TEXT_DARK;
    display: inline-block;
    font-weight: bold;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
}

.headerContainer {
    color: $AXS_TEXT_DARK;
    margin-bottom: 1rem;
    padding-top: 1.25rem;
}

.info {
    color: initial;
    max-width: 640px;
}

.inputsContainer {
    padding: 24px 32px 64px 32px;
    display: flex;
    flex-direction: row;
    max-width: 1312px;
    gap: 24px;

    form{
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        height: fit-content;
        align-items: center;
        max-width: 640px;
        margin-bottom: 32px;
    }

    .rightCol{
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        height: fit-content;
        align-items: center;
        max-width: 640px;
    }
}

.inputWarningContainer {
    background-color: #fdecea;
    border-radius: 4px;
    color: $SRAM_RED;
    padding: 1rem;
    text-align: center;

}

.label {
    text-align: left;
}

.pressureContainer {
    background-color: $AXS_WEB_BG;
    border-radius: 4px;
    font-weight: bold;
    margin-bottom: 1rem;
    min-width: 150px;
    padding: 1.25rem;
    width: auto;

}

 .calcAnimate {
        animation: colorFade 500ms;
    }

    @keyframes colorFade {
        0% {
            background-color: #BCBFC3;
        }
        
        100% {
            background-color: $AXS_WEB_BG;
        }
    }

.pressureLabel {
    color: $AXS_TEXT_DARK;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Roboto';
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.pressureTitle {
    color: $AXS_TEXT_DARK;
    font-weight: 700;
    padding-bottom: 1rem;
}

.pressureValue {
    color: $AXS_TEXT_DARK;
    font-size: 2rem;
    font-weight: 600;
    margin: auto;
}

.pressureSuggestions{
    width: 100%;
    background-color: $WHITE;
    border-radius: 4px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
    color: $BLACK;
    height: fit-content;
}

.recommendedPressureContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 1rem;
    width: 100%;
    padding-inline: 32px;
}

.rotateIcon {
    transform: rotate(90deg);
}

.saveButtonContainer {
    margin-top: 1rem;
    text-align: center;
    padding-inline: 32px;
    width: 100%;

    .button{
        width: 100%;
    }
}

.sectionHeader {
    font-size: 1.33rem;
    font-weight: 500;
    padding: 2rem;
    padding-left: 0;
    text-align: left;
    text-transform: uppercase;
}

.tireRimWidthTable {
    background-color: $TRANSPARENT;
    border: 0;
    color: inherit;
    font-family: inherit;
    font-size: 1.17rem;
    font-weight: bold;
    padding: 0 0.25rem;
}

@media only screen and (min-width: map-get($grid-breakpoints, md)) {
    .rightCol{
           position: sticky;
            top: 24px;
        }
}

@media only screen and (max-width: map-get($grid-breakpoints, md)) {
    .header {
        padding-top: 1rem;
    }

    .inputsContainer {
         padding-inline: 0;
    }

    .disclaimer {
        padding-bottom: 3rem;
    }

    .unitsRow {
        width: auto;
    }
}

@media only screen and (max-width: 975px) {
    .inputsContainer {
        flex-direction: column;
    }
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
    .pressureSuggestions {
        min-width: none;
    }

    .pressureContainer {
        padding-inline: none;
    }

    .inputsContainer{
        min-width: none;
    }

    .container{
        padding-inline: 0;
    }
}
