@import '../../index.scss';

.content {
    position: relative;
}

.expandButton {
    background: $TRANSPARENT;
    border: 0;
    position: absolute;
    right: 1.5rem;
    top: 0;


    &:focus {
        outline: 0;
    }
}

.expandButtonContainer {
    display: flex;
    justify-content: flex-end;
}

.modalContent {
    color: initial;
}
