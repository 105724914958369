@import 'colors.scss';

a {
    text-decoration: none;
}

body {
    background-color: $AXS_WEB_BG;
    font-family: "Roboto", sans-serif;
    /* Height set here for infinite scroll */
    height: 100vh;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    cursor: pointer;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
    font-size: 14px;
}

ul {
    padding-inline-start: 0px;
}

#root {
    min-height: 100vh;
}

.flex {
    display: flex;
}

.sticky {
    position: sticky;
}

.space-around {
    justify-content: space-around;
}
