@import '../../constants/scss/constants.scss';
@import '../../index.scss';

.card {
    background-color: $TRANSPARENT;
    border: none;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: auto;
    overflow: hidden;
    width: 400px;
}

.container {
    background-color: #404047;
    display: flex;
    flex: 1;
}

.content {
    background-color: $WHITE;
    flex: 1;
    padding: 2rem;
    position: relative;
}

.description {
    font-size: 1.5rem;
    text-align: center;
}

.errorIcon {
    height: 100%;
    width: auto;
}

.iconContainer {
    display: block;
    height: 4rem;
    margin: 1rem auto;
    width: 4rem;
}

.successIcon {
    border: 2px solid $GREEN_FOLAGE;
    border-radius: 50%;
    color: $GREEN_FOLAGE;
    font-size: 2.5rem;
    height: 100%;
    text-align: center;
    width: 100%;
}
