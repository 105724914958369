@import '../../../index.scss';
@import '../../../constants/scss/constants.scss';

.confirmationModalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 15%;
    width: 100%;
    height: 100%;
    background-color: transparent;



    .infoContent{
        padding: 0;
        color: white;
        background-color: $SRAM_900;
        border-radius: 10px;
        padding-inline: 0;
        font-size: 1rem;
        padding-top: 1rem;

        .btnContainer{
            display: flex;
            flex-direction: row-reverse;
            width: 100%;
            border-top: 1px solid rgba($SRAM_600, 0.3);

            :nth-child(2){
                border-right: 1px solid rgba($SRAM_600, 0.3);
            }
        }

        .button{
            border-radius: 2px;
            color: $WHITE;
            font-size: 1rem;
            font-weight: 300;
            padding: 3px;
            width: 100%;
            padding: .8rem;

          &:active {
                background-color: $SRAM_800;
            }
        }


        .infoText{
            text-align: center;
        }

        .messageText {
            padding: 1rem;
            font-size: 1rem;
        }
    }
}

.infoContent{
    padding: 0;
    color: white;  
    
}