@import '../../../../constants/scss/constants.scss';
@import '../../../../index.scss';

.collapsablePanelButton {
    align-items: center;
    background-color: $TRANSPARENT;
    color: $SRAM_800;
    display: flex;
    font-size: 1.375rem;
    font-weight: bold;
    justify-content: space-between;
}

.container {
    background-color: $TRANSPARENT;
    padding: 1rem;
}

.content {
    background-color: $TRANSPARENT;
}

.indicator {
    color: $SRAM_RED;
    height: auto;
    transform: rotate(-90deg);
    transition: transform 0.05s;
    width: 10px;
}

.rotateIcon {
    transform: rotate(90deg);
}

.resetButton {
    align-items: center;
    background-color: $SRAM_RED;
    border-radius: 3px;
    color: white;
    display: flex;
    justify-content: center;
    margin-left: auto;
    padding: 0.5rem;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
        background-color: darken($color: $SRAM_RED, $amount: 10%);
        color: white;
    }
}

.resetButtonDisabled {
    opacity: 0.5;
    pointer-events: none;
}

.resetButtonFloating {
    align-items: center;
    background-color: $SRAM_RED;
    border-radius: 50%;
    bottom: 0;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
    box-sizing: border-box;
    color: white;
    display: flex;
    height: 56px;
    justify-content: center;
    margin: 1rem;
    padding: 0;
    position: fixed;
    right: 0;
    text-decoration: none;
    transition:
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        transform 200ms ease;
    width: 56px;
    z-index: 20;

    &:active {
        box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12);
    }

    &:hover {
        background-color: darken($color: $SRAM_RED, $amount: 10%);
        color: white;
    }
}

.resetButtonFloatingOutsideViewPort {
    transform: translateY(calc(100% + 2rem));
}

.refreshIcon {
    font-size: 2rem;
    transform: scale(-1, 1);
}

.subtitle {
    border-bottom: 1px solid $SRAM_500;
    font-size: 0.75rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    width: 100%;
}

.trimButton {
    background-color: $GREEN_LIGHT;
    border: none;
    border-radius: 3px;
    color: white;
    padding: 0.5rem;
    text-decoration: none;
    text-transform: uppercase;

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: darken($color: $GREEN_LIGHT, $amount: 10%);
    }
}

.trimButtonDisabled {
    opacity: 0.5;
    pointer-events: none;
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .resetButtonFloating {
        height: 40px;
        margin-bottom: 52px;
        width: 40px;
    }

    .refreshIcon {
        font-size: 1.5rem;
    }
}
