/* autoprefixer grid: autoplace */

.container {
    border-top: 1px solid #BBBFC4;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1.5fr 1fr 2fr 0.5fr;
    grid-template-rows: auto;
    min-height: 2rem;

    & > * {
        align-self: center;
    }
}

.deleteIcon {
    height: 1rem;
    width: auto;
}

.errorIcon {
    height: 1.5rem;
    width: auto;
}

.deleteIconButton {
    background-color: #00000000;
    border: none;
    height: 100%;
    padding-right: 1rem;
    text-align: right;

    &:focus {
        outline: none;
    }
}

.failed {
    color: #E51937;
}

.nameContainer {
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.percentageBar {
    background-color: #BCC0C5;
    font-weight: bold;
    height: 1rem;
    position: relative;
    z-index: 1;
}

.percentageContainer {
    position: relative;
    width: 100%;
}

.progressBar {
    font-size: 1rem;
    margin-bottom: 1px;
    min-height: 0.8rem;
}
