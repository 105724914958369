@import '../../../colors.scss';

.label {
    color: $AXS_TEXT_GREY;
    font-size: 0.875rem;
    font-weight: lighter;
}

.value {
    color: $AXS_TEXT_DARK;
    font-size: 1.375rem;
    font-weight: bold
}
