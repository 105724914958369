@import '../../../constants/scss/constants.scss';
@import '../../../colors.scss';

.actionButton {
    background-color: $TRANSPARENT;
    border: none;
    color: $AXS_TEXT_LIGHT_GREY;
    font-size: 1.17rem;
    font-weight: 300;
    height: 100%;
    padding: 0;
    text-align: left;
    text-decoration: none;
    width: 100%;

    &:active {
        outline: none
    }

    &:focus {
        outline: none
    }

    &:hover {
        color: $SRAM_RED;
        text-decoration: none;
    }
}

.dropdown {
    background-color: $WHITE;
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    min-width: 250px;
    overflow: auto;
    right: 0;
    top: 100%;
}

.moreIcon {
    height: auto;
    width: 2rem;
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .dropdown {
        display: none;
    }
}
