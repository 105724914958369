@import '../../../constants/scss/constants.scss';
@import '../../../colors.scss';

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.content {
    margin-top: 2rem;
}

.header {
    color: $AXS_TEXT_DARK;
    font-size: 1.33rem;
    font-weight: 500;
}

.text {
    color: $AXS_TEXT_DARK;
    font-size: 1.17rem;
    font-weight: 300;
    padding-bottom: 1rem;
}

.title {
    color: $AXS_TEXT_DARK;
    font-size: 2rem;
    font-weight: 300;
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .title {
        padding: 2rem 0 0 1rem;
    }
}
