@import '../../colors';
@import '../../index.scss';

.button {
    background-color: $SRAM_200;
    border: 1px solid $SRAM_200;
    color: $BLACK;
    font-size: 1rem;
    padding: .375rem .75rem;
    min-width: 100px;

    &:active {
        box-shadow: none;
    }

    &:focus {
        outline: none;
    }

    &:first-of-type {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
    }

    &:hover {
        background-color: $SRAM_500;
        border-color: $SRAM_500;
        color: $WHITE;
    }

    &:last-of-type {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
}

.buttonGroup {
    @extend .flex;

    background-color: $TRANSPARENT;
    flex-wrap: wrap;
}
