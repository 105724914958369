@import '../../../../../index.scss';

.container {
    background-color: $SRAM_100;
    display: flex;
    font-size: 1rem;
    min-height: 4rem;
    margin-bottom: 0.25rem;
    padding: 0 1rem;
    width: 100%;
}

.label {
    font-weight: bold;
}

.labelContainer {
    min-width: 125px;
    padding-right: 1rem;
    text-align: right;
    width: 125px;
    word-break: break-word;
}

.percentageBar {
    background-color: $SRAM_400;
    color: $WHITE;
    font-weight: bold;
    height: 100%;
    transition: width 0.5s;
    transition-timing-function: ease-out;
    -webkit-transition: width 0.5s;
}

.percentageContainer {
    display: flex;
    flex: 1;
    padding-right: 0.5rem;
}

.percentageLabelContainer {
    margin-left: auto;
    text-align: right;
    width: 60px;
    min-width: 60px;
}
