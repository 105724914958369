@import '../../../colors';

.container {
    background-color: $WHITE;
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    margin: 1rem 0 2rem 0;
}

.content {
    padding: 2rem;
}

.finePrint {
    padding-top: 2rem;
}

.guideContent {
    border-bottom: 1px solid $SRAM_600;
    margin-left: 3rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.header {
    color: $SRAM_900;
    font-size: 1.75rem;
    font-weight: 700;
}

.text {
    color: $SRAM_700;
    font-size: 1.17rem;
    font-weight: 300;
}
