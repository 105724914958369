@import '../../../../../colors.scss';

.bar {
    min-height: 35px;
    transition: width 0.5s;
    transition-timing-function: ease-out;
    -webkit-transition: width 0.5s;
}

.barContainer {
    position: relative;
    width: 100%;
}

.container {
    background-color: $SRAM_100;
    display: flex;
    font-size: 1rem;
    margin-bottom: 1px;
    min-height: 2.5rem;
    padding-right: 1rem;
    width: 100%;
}

.duration {
    font-size: 1.25rem;
    margin-left: auto;
    text-align: right;
    width: 100px;
}

.labelContainer {
    font-size: 1.25rem;
    font-weight: bold;
    /* min width to handle when there is 1 character instead of 2 in gear label*/
    min-width: 60px;
    padding: 0 1rem;
    text-align: right;
    width: 60px;
}

.backgroundPercentageBar {
    background-color: $SRAM_300;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
}

.percentageBar {
    background-color: $SRAM_RED;
    border: 0;
    height: 100%;
    padding: 0;
    width: 100%;
}

.percentageContainer {
    position: relative;
    width: 100%;
}
