@import '../../../index.scss';

.container {
    align-items: center;
    border-bottom: 1px solid $SRAM_200;
    display: flex;
    padding: 1rem 0 1rem 0;
}

.content {
    align-items: center;
    display: flex;
    flex: 1;
    padding: 0 1rem;
}

.image {
    height: 50px;
    padding: 0.5rem 0;
    width: 25px;
}

.labelContainer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-left: 1rem;
    text-align: right;
    width: 7rem;
}

.typeaheadInput {
    width: 100%;

    div{
        width: 100%;
    }

    input {
        width: 100%;
        background-color: $SRAM_100;
        border: none;
        border-radius: 0;
        color: $AXS_TEXT_DARK !important;
        font-size: 1.17rem;
        height: 24px;
        padding: 0px;
        padding-left: 0.5rem;
    }
}