@import '../../../../colors';

.container {
    width: 100%;
}

.indicator {
    color: $SRAM_800;
    height: auto;
    transform: rotate(-90deg);
    transition: transform 0.05s;
    width: 10px;
}

.labelContainer {
    align-items: center;
    background-color: $WHITE;
    border: none;
    display: flex;
    justify-content: space-between;
    outline: none;
    width: 100%;
}

.rotateIcon {
    transform: rotate(90deg);
}
