@import '../../../../index.scss';

.activityDate {
    max-width: 180px;
    white-space: nowrap;
    width: 180px;
}

.activityName {
    color: $AXS_TEXT_DARK_BLUE;
}

.activityNameCell {
    max-width: 20rem;
    min-width: 20rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 20rem;
}

.activityType {
    text-transform: capitalize;
}

.bikeCell {
    max-width: 8rem;
    min-width: 8rem;
    width: 8rem;
}

.bikeImage {
    height: 3rem;
    object-fit: cover;
    width: 6rem;
}

.button {
    background-color: $TRANSPARENT;
    border: none;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.buttonEdit {
    color: $AXS_TEXT_DARK_BLUE;
    font-size: 1.25rem;
}

.deleteIcon {
    height: 1.5rem;
    width: 1.5rem;
}

.buttonsGroup {
    display: flex;
    justify-content: space-evenly;
}

.row {
    height: 4rem;

    &:nth-of-type(odd) {
        background-color: $SRAM_50;
    }

    & > td {
      padding: 0.5rem;
    }
}
