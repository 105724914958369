@import '../../../constants/scss/constants.scss';
@import '../../../index.scss';


.container {
    max-width: 100dvw;
    min-height: 100dvh;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 0;
    background-color: $BLACK;
    overflow: auto;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 6rem;
    padding: 1rem;
    background-color: $BLACK;
    padding-top: 2rem;

    .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 2.5rem;
        padding: 0 0.5rem;
        color: $WHITE;
        font: 'Roboto', sans-serif;
        font-weight: 500;
        line-height: 20px;
    }
}

.section{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 0;
    overflow: auto;


    .sectionTitle {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: fit-content;
        font-size: .8rem;
        padding: 0 0.5rem;
        color: $SRAM_600;
        font: 'Roboto', sans-serif;
        line-height: 20px;
        padding: .7rem;
        padding-inline-start: 20px;
        border-top: 1px solid #1C1C1E;
        border-bottom: 1px solid #1C1C1E;
    }
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    background-color: #1C1C1E;
    border-bottom: 1px solid $SRAM_600;
    padding: 10px 20px;
    cursor: pointer;
    color: $WHITE;
    z-index: 1;

    select{
        border: none;
    }

    .rowTitle{
        font-size: 1rem;
        color: $WHITE;
    }

    .arrow{
        color: white;
        padding: 0;
    }

    .rowEnd{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;
        color:  $SRAM_600;
    }
}

.rowDesc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    background-color: #1C1C1E;
    border-bottom: 1px solid $SRAM_600;
    padding: 10px 20px;
    cursor: pointer;
    color: $WHITE;
    z-index: 1;
    font-size: .8rem;
    color: $SRAM_600;
}