@import '../../../../colors.scss';

.channelTitle {
    text-align: center;
}

.header {
    color: $AXS_TEXT_DARK;
    font-size: 1.33rem;
    font-weight: 500;
    text-align: left;
}

.notificationsHeading {
    color: $AXS_TEXT_DARK;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    text-decoration: underline;
}

.notificationsPreferenceColumn {
    flex-basis: 33%;
    flex-grow: 0;
}

.notificationsPreferenceRow {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    margin-bottom: 0;
}

.valueContainer {
    align-items: center;
    display: flex;
    justify-content: center;
}
