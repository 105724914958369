@import '../../../index.scss';
@import '../../../constants/scss/constants.scss';

.bike {
    background-color: $WHITE;
    border: none;
    display: block;
    padding: 0;
    width: 100%;

    &:focus {
        outline: none;
    }
}

.bikeContainer {
    min-width: 10rem;
    padding-bottom: 1rem;
}

.button {
    align-items: center;
    border: none;
    border-radius: 3px;
    height: 40px;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    text-decoration: none;
    min-width: 120px;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.chainGuideLink {
    color: $WHITE;

    &:hover {
        color: $WHITE;
        text-decoration: none;
    }
}

.componentContainer {
    display: flex;
}

.container {
    background-color: $WHITE;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    min-height: 246px;
    padding: 2rem;
}

.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: auto 0;
}

.header {
    color: $SRAM_RED;
    font-size: 1.5rem;
    font-weight: 500;
    white-space: nowrap;
}

.headerContainer {
    border-bottom: 1px solid $SRAM_400;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
}

.image {
    height: 195px;
    margin: auto;
}

.informationContent {
    align-items: center;
    display: flex;
    flex-direction: row;
    line-height: 1.05rem;
    margin-top: 0.5rem;
    width: 100%;
}

.informationHeader {
    color: $AXS_TEXT_DARK;
    font-size: 1.33rem;
    font-weight: 700;
}

.informationLabel {
    color: $AXS_TEXT_DARK;
    font-size: .83rem;
    font-weight: bold;
}

.informationValue {
    color: $SRAM_700;
    font-size: .83rem;
    font-weight: 300;
}

.statusTag {
    background-color: $SRAM_RED;
    border-radius: 0.25rem;
    color: $WHITE;
    display: inline-block;
    font-weight: bold;
    margin-right: 0.25rem;
    padding: 0.25rem 0.5rem;
}

.subHeader {
    color: $SRAM_700;
    font-size: .95rem;
    font-weight: 500;
    padding-bottom: 0.5rem;
}

.textContainer {
    align-self: baseline;
}

.guideButton {
    background-image: linear-gradient(0.25turn, $PINK, $SRAM_RED 22%);
    border-radius: 3px;
    color: $WHITE;
    text-transform: uppercase;
}

.guideButtonContainer {
    margin-left: auto;
    margin-top: auto;
    padding-top: 1rem;
}

.uppercase {
    color: $SRAM_700;
    font-size: .95rem;
    font-weight: 500;
    text-transform: uppercase;
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .componentContainer {
        flex-direction: column;
    }

    .image {
        height: 100%;
        width: 195px;
    }

    .tyrePressureButtonContainer {
        margin: 0;
        padding-top: 1rem;
    }
}
