@import '../../../constants/scss/constants.scss';
@import '../../../index.scss';

.activityHeaderContainer {
    display: flex;
    justify-content: space-between;
}

.activityHeaderText {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    line-height: 1.1;
}

.activityDropdown {
    position: relative;
    z-index: 1;
}

.activityType {
    font-weight: bold;

    &::first-letter {
        text-transform: capitalize;
    }
}

.bikeButton {
    background-color: $SRAM_200;
    border: none;
    font-weight: bold;
    height: 40px;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;

    &:active {
        outline: none
    }

    &:focus {
        outline: none
    }
}

.bikeElement {
    height: 40px;
    width: 90px;
}

.column {
    display: flex;
    justify-content: space-between;
}

.columnItem {
    text-align: left;
}

.columnItemMain {
    flex: 1;
    min-width: 0;
    padding-right: 1rem;
    text-align: left;
}

.columnItemSecondary {
    text-align: left;
    word-wrap: none;
}

.container {
    display: flex;
    justify-content: space-around;
    padding: 1rem;
}

.label {
    color: $AXS_TEXT_GREY;
    font-size: 0.875rem;
    font-weight: lighter;
}

.middleBorder {
    background-color: $SRAM_600;
    min-height: inherit;
    width: 1px;
}

select.inputField {
    appearance: none;
    cursor: pointer;
    background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%20%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%20%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2214px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2014%2012%22%20enable-background%3D%22new%200%200%2014%2012%22%20xml%3Aspace%3D%22preserve%22%3E%20%3Cpolygon%20points%3D%223.862%2C7.931%200%2C4.069%207.725%2C4.069%20%22/%3E%3C/svg%3E);
    background-position: right 50%;
    background-repeat: no-repeat;
}

.subTitle {
    font-size: 1.25rem;
    font-weight: 300;
}

.value {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::first-letter {
        text-transform: capitalize;
    }
}

@media only screen and (max-width: map-get($grid-breakpoints, md)) {
    .activityHeaderText {
        align-items: flex-start;
        flex-direction: column;
    }

    .column {
        flex-direction: column;
    }

    .container {
        flex-direction: column;
    }
}
