@import '../../../../index.scss';

.distanceValue {
    font-size: 1.75rem;
    font-weight: bold;
}

.endTime {
    background-color: $RED;
}

.icon {
    height: 35px;
    width: auto;
}

.label {
    color: $AXS_TEXT_GREY;
    font-size: 0.875rem;
    font-weight: lighter;
    margin-top: 0.5rem;
}

.row {
    justify-content: center;
    min-height: 50px;
    margin-bottom: 1rem;
    padding: 0.5rem;
    padding-bottom: 1rem;
    position: relative;

    &::after {
        border-bottom: 1px solid $AXS_TEXT_GREY;
        bottom: 0;
        content: "";
        left: 20%;
        position: absolute;
        right: 0;
        vertical-align: baseline;
        width: 60%;
    }
}

.rowContainer {
    flex: 1;
    padding-bottom: 30px;
}

.startTime {
    background-color: $GREEN_FOLAGE;
}

.time {
    border-radius: 50%;
    height: 0.5rem;
    margin-right: 0.5rem;
    width: 0.5rem;
}

.timeContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.timesContainer {
    margin: auto;
    max-width: 85px;
    width: fit-content;
}

.value {
    font-size: 1.125rem;
    font-weight: bold;
}
