@import '../../../colors.scss';
@import '../../../constants/scss/constants.scss';

.container{
    width: 100%;
    background-color: $WHITE;
    border-radius: 4px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
    color: $BLACK;

    .section{
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: flex-start;
        align-items: flex-start;

        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: $BLACK;  
    }

    .chips{
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        font-size: 12px;
        font-weight: 400px;
        line-height: 25px;

        &.selected{
            background-color: $SRAM_RED;
            border: 1px solid $SRAM_RED;
            color: white;
        }
    }
}

.button {
    background-color: $WHITE;
    border-color: $SRAM_RED;
    color: $SRAM_RED;
    min-width: 60px;
    text-transform: uppercase;

    &:hover {
        background-color: #E5193715;
        border-color: $SRAM_RED;
        color: $SRAM_RED;
    }
}

.inputWithUnits {
    border: 1px solid #ededee;
    flex: 1;
    padding-left: 0.5rem;
    width: 100%;
}

.inputContainer{
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
}

.section{
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: $BLACK;  

        .header{
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            width: 100%;
            
            .error{
                color: $SRAM_RED;
                font-size: 12px;
                font-weight: 400;
                display: flex;
                flex-direction: column;
                align-content: flex-end;
                align-items: flex-end;
                line-height: 12px;
            }
        }
    }

.toggleSelector {
    margin: 0;
}

.units {
    align-items: center;
    background-color: $SRAM_200;
    color: $AXS_TEXT_DARK;
    display: flex;
    font-weight: 400;
    justify-content: center;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media only screen and (max-device-width: map-get($grid-breakpoints, md)) {
    .unitsRow {
        width: auto;
    }
}
