@import '../../../colors';

.column {
    border: 1px solid $WHITE;
    color: $SRAM_900;
    font-size: 1rem;
    padding-left: 1rem;
    text-align: left;
    width: 50%;
}

.label {
    font-weight: 700;
}

.row {
    height: 40px;

    &:nth-of-type(odd) {
        background-color: $SRAM_200;
    }
}
