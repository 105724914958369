@import '../../../colors.scss';

.container {
    background-color: $TRANSPARENT;
    border: none;
    color: $AXS_TEXT_LIGHT_GREY;
    font-size: 1.17rem;
    font-weight: 300;
    outline: none;
    padding: 0;
    text-decoration: none;
    width: 100%;

    &:hover {
        color: $SRAM_RED;
        text-decoration: none;
    }
}
