@import '../../../colors.scss';

.buttonContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.buttonSave {
    font-size: 1rem;
    margin-left: 1rem;
    width: 125px;
}

.container {
    background-color: $WHITE;
    padding-top: 2rem;
}
