@import '../../colors.scss';

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logOutButton {
    background-color: $TRANSPARENT;
    border: none;
    color: $AXS_TEXT_LIGHT_GREY;
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: auto;
    margin-top: 1rem;
    outline: none;
    padding: 0;
    text-decoration: underline;
    width: 100%;

    &:hover {
        color: $BLUE_CHALK;
    }
}
