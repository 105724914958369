@import '../../../../index.scss';

.border {
    border-bottom: 1px solid $SRAM_600;
    padding-bottom: 0.5rem;
}

.label {
    color: $AXS_TEXT_GREY;
    font-size: 0.875rem;
    font-weight: lighter;
}

.row {
    align-items: center;
    display: flex;
    padding-top: 0.5rem;
    width: 100%;
}

.value {
    color: $AXS_TEXT_DARK;
    font-size: 1.375rem;
    font-weight: bold;
    margin-left: auto;
}
