@import '../../colors.scss';

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
}

.container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 1rem;
    width: 100%;
}

.content {
    background-color: $WHITE;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 $OPAQUE,
               0 6px 20px 0 $OPAQUE_LIGHT;
    padding: 1rem;
    padding-bottom: 2.5rem;
    width: 100%;
}

.header {
    color: $SRAM_RED;
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
}
