@import '../../index.scss';

.button {
    align-items: center;
    background-color: $SRAM_500;
    border: none;
    display: flex;
    justify-content: center;
    height: 50px;
    opacity: 0.2;
    overflow: hidden;
    position: sticky;
    /* top 4rem is for sticky buttons to stay underneath toolbar */
    top: 4rem;
    width: 50px;
    z-index: 3;

    &:focus {
        outline: none;
    }
}

.buttonLeft {
    border-radius: 0px 50% 50% 0px;
    padding-right: 15px;
}

.buttonRight {
    border-radius: 50% 0px 0px 50%;
    padding-left: 15px;
}

.container {
    align-items: space-between;
    display: flex;
    flex-wrap: wrap;
    min-height: 5rem;
    padding-top: 1.5rem;
    position: relative;
}

.content {
    position: relative;
    min-height: 23rem;
}

.contentContainer {
    flex-basis: 100%;
    // firefox requires max/min-width set for flex-basis to work
    max-width: 100%;
    position: relative;
    width: 100%;
}

.dots {
    display: inline-block;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.dotsContainer {
    position: relative;

    &::before {
        border-top: 1px solid $AXS_TEXT_GREY;
        content: "";
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        position: absolute;
        vertical-align: baseline;
        width: 40%;
    }
}

.dot {
    color: $SRAM_400;
    display: inline;
    font-size: 30px;
    margin-right: 5px;
}

.headerText {
    font-size: 1.5rem;
    font-weight: lighter;
    padding: 0.5rem;
}

.title {
    flex: 1;
    justify-content: center;
    text-align: center;
}

.triangleLeft {
    border-bottom: 0.9375rem solid $TRANSPARENT;
    border-right: 1.0625rem solid $WHITE;
    border-top: 0.9375rem solid $TRANSPARENT;
    height: 0px;
    width: 0px;
}

.triangleRight {
    border-bottom: 0.9375rem solid $TRANSPARENT;
    border-left: 1.0625rem solid $WHITE;
    border-top: 0.9375rem solid $TRANSPARENT;
    height: 0px;
    width: 0px;
}
