@import '../../../colors.scss';
@import '../../../constants/scss/constants.scss';

.chipContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px 8px 12px;
    border-radius: 4px;
    gap: 8px;
    width: fit-content;
    height: 41px;
    border: 1px solid #81868B;
    color: #81868B;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;

    &:hover {
        background-color: $AXS_WEB_BG;
    }

    .icon{
        width: 24px;
        height: 24px;
    }

    &.selected {
        background-color: $SRAM_RED;
        border: 1px solid $SRAM_RED;
        color: white;
    }

    &.selected:hover {
        background-color: $SRAM_RED;
        border: 1px solid $SRAM_RED;
        color: white;
    } 
}

.icon {
    color: 81868B;
}
