@import '../../colors.scss';

.container {
    height: 100%;
    padding-top: 2rem;
}

.content {
    flex: 1;
    margin: auto;
    max-width: 592px;
    padding-bottom: 2rem;
    width: 100%;
}

.markAllButton {
    background-color: $TRANSPARENT;
    border: none;
    color: $AXS_TEXT_DARK;
    font-size: 1.17rem;
    margin-bottom: 1.5rem;
    margin-left: 1rem;

    &:hover {
        color: $SRAM_500;
    }
}

.noMessages {
    color: $AXS_TEXT_DARK;
    display: flex;
    font-size: 1.75rem;
    justify-content: center;
    margin-top: 3rem;
}
