@import '../../colors.scss';

.imagePicker {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    position: absolute;
}

.imagePickerContainer {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    max-width: 200px;
    min-width: 100px;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.imagePickerIcon {
    color: $AXS_TEXT_DARK;
    height: auto;
    margin-right: 0.3rem;
    width: 20px;
}

.input {
    background-color: $SRAM_100;
    border: none;
    color: $AXS_TEXT_DARK;
    font-size: 1.17rem;
    height: 24px;
    padding-left: 0.5rem;
    width: 100%;
}

/* Have to override default date appearance */
select.input {
    appearance: none;
    cursor: pointer;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%20%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%20%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2214px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2014%2012%22%20enable-background%3D%22new%200%200%2014%2012%22%20xml%3Aspace%3D%22preserve%22%3E%20%3Cpolygon%20points%3D%223.862%2C7.931%200%2C4.069%207.725%2C4.069%20%22/%3E%3C/svg%3E);
}

// Nested style needed here to target the input within the 3rd party typeahead component
.typeaheadInput {
    width: 100%;

    input {
        width: 100%;
        background-color: $SRAM_100;
        border: none;
        border-radius: 0;
        color: $AXS_TEXT_DARK !important;
        font-size: 1.17rem;
        height: 24px;
        padding: 0px;
        padding-left: 0.5rem;
    }
}

.units {
    color: $AXS_TEXT_DARK;
    margin-left: 1rem;
}

.divider {
    background-color: $AXS_TEXT_GREY;
    height: 25%;
    margin: auto;
    width: 1px;
}
