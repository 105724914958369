@import '../../constants/scss/constants.scss';
@import '../../index.scss';

.container {
    background-color: $WHITE;
    display: flex;
    flex: auto;
    padding: 4rem;
}

.content {
    flex: auto;
    justify-content: center;
    height: 100%;
    margin-left: 3rem;
    max-width: map-get($grid-breakpoints, sm);
}

.header {
    color: $AXS_TEXT_DARK;
    font-size: 1.33rem;
    font-weight: 500;
    padding-bottom: 1.5rem;
}

.sideMenu {
    border-right: 1px solid $AXS_TEXT_DARK;
    display: block;
    min-width: 300px;
    padding-right: 2rem;
    width: 300px;
}

.sideMenuButton {
    background-color: $TRANSPARENT;
    border: none;
    color: $AXS_TEXT_DARK;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    font-weight: 300;
    padding: 0px;
    text-align: left;

    &:focus {
        outline: 0;
    }

    &:hover {
        color: initial;
        text-decoration: none;
    }
}

.sideSubMenuButton {
    margin-left: 1rem;
}

@media (max-width: map-get($grid-breakpoints, lg)) {
    .container {
        flex-direction: column;
        padding: 0;
    }

    .content {
        margin-left: 0px;
        padding: 1rem;
    }

    .sideMenu {
        border-bottom: 5px solid $AXS_TEXT_DARK;
        border-right: none;
        margin-bottom: 0px;
        padding: 1rem;
        width: 100%;
    }

    .sideMenuButton {
        padding-left: 1rem;
    }
}
