@import '../../index.scss';

.container {
    display: flex;
    flex-direction: column;
    min-height: inherit;
}

.content {
    display: flex;
    flex: auto;
    flex-direction: column;
    margin: auto;
    max-width: 592px;
    padding-top: 50px;
    width: 100%;
}

.errorContainer {
    box-shadow: 0 4px 8px 0 $OPAQUE, 0 6px 20px 0 $OPAQUE_LIGHT;
    margin-bottom: 2rem;
    margin-top: 2rem;
    padding: 3rem;
}

.help {
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.subtitle {
    font-size: 1.5rem;
}

.suggestions {
    list-style-position: inside;
}

.title {
    font-size: 2rem;
    font-weight: bold;
}
