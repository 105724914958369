.button {
    font-size: 1.17rem;
    font-weight: 700;
    height: 45px;
    outline: none;
    padding: 0 2rem 0 2rem;
    text-transform: uppercase;

    &:active {
        outline: none
    }

    &:focus {
        outline: none
    }
}
