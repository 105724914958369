@import '../../colors.scss';
@import '../../index.scss';

.buttonContainer {
    display: flex;
    justify-content: space-around;
    margin-top: auto;
    width: 100%;
}

.cropperContainer {
    margin: 2rem auto;
}

.cropperContainer > img {
    max-width: 100%;
}
