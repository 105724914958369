@import '../../../../../index.scss';

.alertContainer {
    position: relative;
    width: 70%;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.alertIcon {
    height: auto;
    position: absolute;
    left: 0;
    width: 2rem;
}

.alertLabelContainer {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    text-align: center;
    width: 100%;
    word-break: break-word;
}

.lastPressure {
    color: $WHITE;
    font-size: 1.6875rem;
    font-weight: 600;
    margin: auto;
}

.lastPressureContainer {
    background-color: $GREEN_LIGHT;
    border-radius: 3px;
    display: flex;
    min-height: 4rem;
    margin: 0.5rem 0;
    padding: 1.25rem;
    position: relative;
    width: 150px;
}

.statusIcon {
    bottom: 0.5rem;
    height: 0.75rem;
    position: absolute;
    right: 0.5rem;
    width: auto;
}

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

.title {
    font-size: 1.125rem;
    font-weight: 600;
    min-width: 75%;
    margin-bottom: 1rem;
}

.subTitle {
    color: $AXS_TEXT_GREY;
    font-size: 0.875rem;
    font-weight: lighter;
}
