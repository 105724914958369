@import '../../../../colors.scss';

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.email {
    color: $AXS_TEXT_DARK;
    font-size: 1.17rem;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: center;
}
