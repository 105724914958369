@import '../../../../colors';

.clearButton {
    background-color: $TRANSPARENT;
    border: none;
    color: $PINK_BABY;
    font-size: 0.8rem;

    &:focus {
        outline: none;
    }
}

.distanceDurationDropdown {
    padding: 0 2rem;
    width: 350px;
}
