@import '../../../../index.scss';

.container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    min-height: 25px;
    padding-right: 30px;
}

.statistic {
    font-weight: bold;
    margin: 0px 0.5rem;
    min-width: 60px;
    text-align: right;
}

.label {
    font-weight: bold;
    margin: auto;
    min-height: 25px;
    text-align: center;
}
