@import '../../../constants/scss/constants.scss';
@import '../../../index.scss';

.accountButtonContainer {
    margin-bottom: 2rem;
}

.bulletPoint {
    margin-left: 3rem;
}

.button {
    max-width: 200px;
    width: 100%;
    padding: 0 1rem;
}

.buttonContainer {
    display: flex;
    justify-content: space-around;
    margin-top: auto;
    padding-top: 1rem;
    width: 100%;
}

.deleteAccountContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.deleteAccountItem {
    border-bottom: none;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.image {
    background-color: $SRAM_RED;
    padding: 1rem;
}

.inputField {
    background-color: $TRANSPARENT;
    border: 1px solid $SRAM_200;
    border-radius: 2px;
    color: $AXS_TEXT_DARK;
    font-size: 1.17rem;
    font-weight: 300;
    height: 100%;
    max-width: 300px;
    padding: 1px;
    width: 100%;
}

.link {
    align-items: center;
    background-color: $SRAM_300;
    border: none;
    border-radius: 3px;
    color: $BLACK;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    text-decoration: none;
    min-width: 120px;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: $SRAM_400;
        color: $BLACK;
        outline: none;
        text-decoration: none;
    }
}

.modalContent {
    color: initial;
    font-weight: 500;
    text-align: left;
}

.subTitle {
    color: $AXS_TEXT_DARK;
    font-size: 14px;
    overflow: hidden;
    word-break: break-word;
}

.title {
    color: $AXS_TEXT_DARK;
    font-size: 1.33rem;
    font-weight: 500;
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .accountButtonContainer {
        margin-bottom: 0.5rem;
    }

    .link {
        background-color: $TRANSPARENT;
        color: $SRAM_700;
        padding: 0;

        &:active {
            background-color: $TRANSPARENT;
        }

        &:focus {
            background-color: $TRANSPARENT;
        }

        &:hover {
            background-color: $TRANSPARENT;
        }
    }
}
