@import '../../../constants/scss/constants.scss';
@import '../../../index.scss';

.arrowButtons {
    display: flex;
    justify-content: space-between;
    width: 8rem;
}

button {
    background-color: $TRANSPARENT;
    border: none;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.buttonArrow {
    background-color:$SRAM_300;
    border-radius: 5px;
    padding: 0.5rem;
}

.clearButton {
    color: $PINK_BABY;
    font-size: 0.8rem;
}

.clearFiltersButton {
    align-items: center;
    color: $SRAM_RED;
    display: flex;
}

.clearButtonIcon {
    margin-right: 0.25rem;
}

.clearFilter {
    padding: 2rem 0;
}

.container {
    padding: 4rem;
}

.header {
    border-bottom: 1px solid $SRAM_600;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.headerContainer {
    margin: auto;
    max-width: map-get($grid-breakpoints, xl);
}

.pageCount {
    padding-bottom: 1rem;
}

.paginationContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
}

.searchBox {
    background-color: $SRAM_200;
    border: none;
    border-radius: 10rem;
    color: $SRAM_700;
    font-size: 1.25rem;
    font-weight: 500;
    height: 3rem;
    margin-bottom: 1rem;
    padding: 1.5rem;
    width: 20rem;

    &::placeholder {
        color: $SRAM_700;
        font-size: 1.2rem;
    }

    &:focus {
        outline: none;
    }
}

.sortIcon {
    color: $SRAM_800;
}

.table {
    width: 100%;
}

.tableContainer {
    overflow-x: scroll;
}

.title {
    font-size: 2rem;
    font-weight: 500;
}

@media only screen and (max-width: map-get($grid-breakpoints, md)) {
    .container {
        padding: 0.5rem;
    }

    .header {
        justify-content: center;
    }
}
