@import '../../index.scss';

.container {
    align-items: center;
    display: flex;
    margin-bottom: 0px;
}

.defaultInput {
    cursor: pointer;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
}

.checkbox {
    background-color: $SRAM_400;
    display: block;
    height: 100%;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;

    &:hover {
        background-color: $SRAM_500;
    }
}

// Checkmark
.checkmark {
    border: solid $WHITE;
    border-width: 0 2px 2px 0;
    bottom: 0;
    height: 50%;
    left: 0;
    margin: auto;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 25%;
}

.inputContainer {
    border-radius: 3px;
    cursor: pointer;
    height: 1.5rem;
    min-height: 1.5rem;
    min-width: 1.5rem;
    overflow: hidden;
    position: relative;
    width: 1.5rem;

    // Style when checked
    & >input:checked {
        // Style the sibling input when checked
        & ~.checkbox {
            background-color:  $AXS_TEXT_GREY;

            // Display the Checkmark
            > .checkmark {
                opacity: 1;
            }
        }

    }
}
