@import '../../../colors.scss';

.container {
    display: inline-block;
    height: 100%;
    position: relative;
    padding: 0 7.5px;
}

.logo {
    color: black;
}

.unreadCountContainer {
    align-items: center;
    background-color: $SRAM_RED;
    border: 1px solid $BLACK;
    border-radius: 50%;
    color: $WHITE;
    display: flex;
    font-size: .75rem;
    font-weight: 500;
    height: 20px;
    justify-content: center;
    position: absolute;
    right: calc(50% - 20px);
    top: 5%;
    width: 20px;
}
