@import '../../../colors';

.actionButton {
    background-color: $TRANSPARENT;
    color: $SRAM_600;
    font-size: 1.17rem;
    font-weight: 300;
    height: 100%;
    padding: 0;
    text-align: left;
    width: 100%;

    &:hover {
        color: $SRAM_RED;
    }
}

.button {
    border: none;
    text-decoration: none;
}

.mobileButtonRow {
    align-items: center;
    border-bottom: 1px solid $SRAM_600;
    color: $SRAM_600;
    display: flex;
    font-size: 1.17rem;
    font-weight: 300;
    height: 0px;
    min-height: 65px;

    &:last-of-type {
        border-bottom: none;
    }
}
