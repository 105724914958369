@import '../../constants/scss/constants.scss';
@import '../../colors.scss';

// TODO: Remove nested styles

.bottom {
    background-color: $AXS_TEXT_DARK;
    display: flex;
    flex-direction: row;
    padding: 1rem 1rem 0;

    a {
        color: $AXS_TEXT_LIGHT_GREY;
        cursor: pointer;
        padding-left: 1rem;
    }

    p {
        color: $AXS_TEXT_LIGHT_GREY;
        justify-self: right;
        text-transform: uppercase;
    }
}

.container {
    background-color: $BLACK;
    color: $WHITE;
    height: auto;
    padding-top: 2rem;
    width: 100%;
    z-index: 15;
}

.top {
    display: flex;
    flex-direction: row;
    font-size: 1.17rem;
    font-weight: 700;
    justify-content: space-around;
    line-height: 1.75rem;
    padding: 2.5rem 4rem 3rem;

    a {
        color: $AXS_TEXT_LIGHT_GREY;
        font-size: 1rem;
        font-weight: 400;
        cursor: pointer;
        line-height: 2rem;
        list-style-type: none;
    }

    li {
        color: $AXS_TEXT_LIGHT_GREY;
        font-size: 1rem;
        font-weight: 700;
        line-height: 2rem;
        list-style-type: none;
    }

    // Microsoft Edge and Internet Explorer add padding to ul tags
    ul {
        padding-left: 0;
    }
}

.logoContainer {
    font-size: 1rem;
    letter-spacing: 2px;
    font-weight: 300;
}

.socialIcons {
    color: $WHITE;
    font-size: 2rem;
    padding-top: 2rem;

    a {
        margin-right: 5rem;

        &:last-child {
            margin-right: 0px;
        }
    }
}

.socialMediaTitle {
    // TODO: Remove nested styles in classes then remove important tags
    color: $WHITE !important;
    font-size: 1.17rem !important;
    text-transform: uppercase;
}

@media (max-width: map-get($grid-breakpoints, xs)) {
    .socialIcons {
        a {
            margin-right: 1rem;
        }
    }
}

@media (max-width: map-get($grid-breakpoints, sm))
{
    .bottom {
        flex-direction: column;
        padding-bottom: 2rem;

        a {
            padding-left: 0;
        }

        p {
            margin-bottom: 0;
        }
    }

    .top {
        flex-direction: column;
        justify-content: left;
        padding-bottom: 2rem;
        padding-left: 1.5rem;
        padding-top: 2rem;

        a {
            color: $AXS_TEXT_LIGHT_GREY;
            font-size: 1rem;
        }

        img {
            height: 3.5rem;
        }

        li {
            color: $AXS_TEXT_LIGHT_GREY;
            font-size: 1rem;
        }
    }

    .socialIcons {
        margin-bottom: 2rem;

        img {
            cursor: pointer;
            height: 2rem;
        }
    }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, md)) {
    .socialIcons {
        a {
            margin-right: 3rem;
        }
    }
}
