@import '../../colors';

.activityContainer {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.clearFilters {
    background-color: $TRANSPARENT;
    border: none;
    color: $AXS_TEXT_DARK_BLUE;
    font-weight: bold;
}

.contentHeader {
    min-height: 50px;
    padding: 0.5rem;
}

.loadMoreContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.loadMoreButton {
    font-size: 1.17rem;
    font-weight: 700;
    height: 45px;
    outline: none;
    padding: 0 2rem 0 2rem;
    text-transform: uppercase;
    background-color: $WHITE;
    border: 1px solid $SRAM_RED;
    color: $SRAM_RED;

    &:hover {
        outline: none;
        color: $WHITE;
        background-color: $SRAM_RED;
    }
}

