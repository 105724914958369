.container {
    padding-top: 43%;
    overflow: hidden;
    position: relative;
}

.image {
    bottom: 0;
    left: 0;
    margin: auto;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
