@import '../../colors.scss';

.buttonHover {
    &:hover {
        background-color: #3C4043;
    }
}

.logo {
    color: white;
}

.externalLink {
    color: $AXS_TEXT_LIGHT_GREY;
    &:hover {
        color: $SRAM_RED;
    }
}