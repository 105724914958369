@import '../../../../constants/scss/constants.scss';
@import '../../../../index.scss';

.axis {
    text {
        font-size: 1rem;
    }
}

.chart {
    overflow: visible;
}

.chartContainer {
    overflow: hidden;
    padding-bottom: 4%;
    padding-left: 10%;
    padding-top: 1rem;
    padding-right: 1px;
}

.container {
    padding-bottom: 1rem;
}

.mobileTimeScale {
    display: none;
}

.resize {
    cursor: ew-resize;
}

.segmentWindow {
    cursor: move;
    fill: #28E523;
    opacity: 0.6;
    stroke: $WHITE;
    stroke-width: 2px;
}

.subtitle {
    font-size: 0.625rem;
}

.title {
    color: $SRAM_800;
    font-size: 1rem;
    font-weight: bold;
}

.xAxis {
    transform: translateY(100%);

    text {
        font-size: 1rem;
    }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
    .chartContainer {
        padding-bottom: 0;
        padding-left: 0;
        padding-top: 0.25rem;
    }

    .mobileTimeScale {
        display: flex;
        font-size: 12px;
        justify-content: space-between;
        padding-top: 5px;
    }

    .axis {
        line {
            display: none;
        }

        text {
            display: none;
        }
    }
}
