@import '../../colors';

.active {
    font-weight: bold;
}

.clearButton {
    background-color: $TRANSPARENT;
    border: none;
    color: $PINK_BABY;
    font-size: 0.8rem;

    &:focus {
        outline: none;
    }
}

.container {
    padding: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
}

.label {
    font-weight: bold;
    width: 30px;
    text-align: left;
    text-transform: capitalize;
}

.row {
    align-items: center;
    display: flex;
    justify-content: left;
    gap: 1rem;
    padding-left: 14px;
    padding: 0.5rem;
}
