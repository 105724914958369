@import '../../../../../../colors.scss';

.edit {
    background-color: $TRANSPARENT;
    border: none;
    color: $AXS_TEXT_DARK_BLUE;
    font-weight: bold;

    &:focus {
        outline: 0;
    }
}
