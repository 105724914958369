@import '../../../constants/scss/constants.scss';
@import '../../../index.scss';

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.fade {
    height: 100%;
}

.releaseNotesContainer {
    overflow-y: auto;
}

.title {
    color: $AXS_TEXT_DARK;
    font-size: 2rem;
    font-weight: 300;
    margin-left: auto;
    margin-right: auto;
    text-transform: capitalize;
}

.titleContainer {
    display: flex;
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .title {
        padding: 2rem 0 0 1rem;
    }
}
