@import '../../../index.scss';
@import '../../../colors.scss';

.backDrop {
    height: 100vh;
    left: 0px;
    position: fixed;
    top: 0px;
    width: 100vw;
    z-index: -1;
}

.button {
    @extend .flex;

    background-color: $TRANSPARENT;
    border: none;
    border-radius: 3px;
    color: $WHITE;
    justify-content: center;
    padding: 0.25rem 1rem;
    text-align: center;
    font-size: 1.33rem;
    font-weight: 700;
    outline: none !important;
}

.container {
    align-items: center;
    display: flex;
    height: 100%;
    overflow: visible;
    position: relative;
}

.dropdownContainer {
    position: absolute;
    top: 100%;
    z-index: 1;
}
