@import '../../constants/scss/constants.scss';
@import '../../index.scss';

.container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .container {
        margin: 0;
    }
}
