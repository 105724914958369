@import '../../../colors';

.collapsableContainer {
    border-bottom: 1px solid $SRAM_400;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.content {
    justify-items: left;
    margin-left: auto;
    margin-right: auto;
    max-width: 492px;
    padding: 0.5rem 0;
    width: 100%;
}

.clearButton {
    background-color: $TRANSPARENT;
    border: none;
    color: $PINK_BABY;
    font-size: 0.8rem;

    &:focus {
        outline: none;
    }
}

.createBike {
    color: $AXS_TEXT_DARK_BLUE;
    font-size: 0.8rem;

    &:hover {
        color: $AXS_TEXT_DARK_BLUE;
        text-decoration: none;
    }
}

.noBikesContainer {
    text-align: left;
}

.noBikesRow {
    padding: 0.5rem 1rem;
}

.slider {
    padding: 1rem 3rem;
}

.subheaderContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.25rem;
}

.subheaderLabel {
    font-size: 1.25rem;
}
