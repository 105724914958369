@import '../../../index.scss';

.link {
    color: $BLUE_MILD;
}

.linkContainer {
    align-items: center;
    display: flex;
    font-size: 0.75rem;
    min-height: 40px;
    padding: 0.5rem;
    padding-right: 0;

    &:nth-of-type(odd) {
        background-color: $SRAM_200;
    }
}

.title {
    font-weight: bold;
    padding: 0.5rem 0;
    text-transform: capitalize;
}
