@import '../../../index.scss';

.container {
    background-color: $TRANSPARENT;
    border: none;
    color: $AXS_TEXT_DARK;
    display: block;
    font-weight: bold;
    margin: auto;
    margin-bottom: 1rem;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;

    &:focus {
        outline: 0;
    }

    &:hover {
        color: $AXS_TEXT_DARK;
        text-decoration: none;
    }
}
