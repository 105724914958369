@import '../../../../../constants/scss/constants.scss';
@import '../../../../../index.scss';

.axis {
    text {
        font-size: 1rem;
    }
}

.chart {
    overflow: visible;
}

.chartContainer {
    height: calc(100vh - 15rem);
    max-height: 400px;
    overflow: hidden;
    padding-bottom: 1.675rem;
    padding-left: 3.25rem;
    padding-top: 1rem;
}

.container {
    padding: 0 1rem;
    padding-bottom: 2rem;
}

.endTime {
    margin-left: auto;
    padding-top: 5px;
}

.indicator {
    fill: none;
    stroke: $BLACK;
    stroke-width: 3;
}

.mobileTimeScale {
    display: none;
}

.title {
    font-size: 1.125rem;
    font-weight: 600;
    padding: 0rem 1rem;
    text-align: left;
    width: 100%;
}

.value {
    font-weight: bold;
    margin: 0px 0.5rem;
    min-width: 60px;
    text-align: right;
}

.valueContainer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    min-height: 25px;
    padding-right: 30px;
}

.startTime {
    padding-top: 5px;
}

.xAxisLabel {
    text-align: center;
}

.yAxisLabel {
    text-align: left;
    text-transform: uppercase;
}

@media (max-width: map-get($grid-breakpoints, sm)) {
    .axis {
        text {
            font-size: 0.7rem;
        }
    }

    .chartContainer {
        padding-bottom: 1.29rem;
        padding-left: 2.25rem;
        padding-top: 0.25rem;
    }

    .mobileTimeScale {
        display: flex;

        font-size: 12px;
    }

    .xAxis {
        line {
            display: none;
        }

        text {
            display: none;
        }
    }
}
