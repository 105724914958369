@import '../../../../../index.scss';

.buttonsContainer {
    display: flex;
    font-size: 0.875rem;
    justify-content: space-around;
    margin-top: 2rem;
    width: 100%;
}

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inputField {
    background-color: $SRAM_100;
    border: none;
    color: $AXS_TEXT_DARK;
    font-size: 1.17rem;
    height: 24px;
    margin-left: 1rem;
    text-align: center;
    width: 4rem;
}

.row {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.rowLabel {
    color: $SRAM_900;
    font-weight: bold;
}

.subHeader {
    color: $AXS_TEXT_LIGHT_GREY;
    font-size: 1.17rem;
    font-weight: 300;
}
