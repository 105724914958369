@import '../../../colors.scss';
@import '../../../constants/scss/constants.scss';

.alert {
    display: flex;
    height: 100%;
    justify-content: center;
    padding-top: .10rem;
    width: 3rem;
}

.alertImage {
    height: 20px;
    width: 20px;
}

.arrow {
    background: url(../../../assets/icons/rightArrow.svg) no-repeat;
    height: 15px;
    margin-top: 1.25rem;
    object-fit: cover;
    width: 10px;
}

.container {
    align-content: center;
    background-color: $WHITE;
    border: none;
    border-radius: 3px;
    box-shadow: 0 6px 4px 0 $OPAQUE_LIGHTEST, 0 3px 3px 0 $OPAQUE_LIGHTEST;
    display: flex;
    margin: .75rem 0;
    min-height: 100px;
    padding: .5rem .25rem .25rem .25rem;
    width: 100%;
    word-break: break-word;

    &:focus {
        outline: none;
    }

    // Turn arrow red when container is hovered
    &:hover {
        .arrow {
            background: url(../../../assets/icons/rightArrowRed.svg) no-repeat;
        }
    }
}

.content {
    flex: 1;
    text-align: left;
}

.dateContainer {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    font-size: .70rem;
    height: calc(50% + 7.5px);
    justify-content: space-between;
    margin-right: 1rem;
    white-space: nowrap;
}

.subTitle {
    color: $AXS_TEXT_DARK;
    font-size: 1rem;
    font-weight: 400;
}

.text {
    margin-top: 1rem;
    white-space: pre-wrap;
}

.title {
    color: $AXS_TEXT_DARK;
    font-size: 1.17rem;
    font-weight: 700;
    margin-bottom: .10rem;
    text-transform: capitalize;
}

.viewed {
    background-color: $SRAM_50;
    border-bottom: 1px solid $SRAM_300;
    border-radius: 0;
    box-shadow: none;
}

@media (max-width: map-get($grid-breakpoints, sm)) {
    .title {
        font-size: 1rem;
    }

    .subTitle {
        font-size: .83rem;
    }
}
