@import '../../../constants/scss/constants.scss';
@import '../../../index.scss';

.content {
    margin-top: 0.5rem;
    padding-bottom: 3rem;
    padding-top: 3rem;
}

.componentsContainer {
    display: flex;
    flex-direction: column;
    padding-top: .5rem;
    padding-bottom: 1.5rem;

    .componentsHeader {
        color: $AXS_TEXT_DARK;
        font-size: .9rem;
        font-weight: 600;
    }

    .component{
        font-size: .9rem;
        font-weight: 300;
    }
}

.deviceCode {
    color: $SRAM_700;
    font-size: .8rem;
    font-weight: 500;
}

.deviceContainer {
    display: flex;
    flex-direction: row;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.deviceDescriptionContainer {
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;
}

.deviceName {
    text-transform: capitalize;
}

.devicesContainer {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1.5rem;
}

.header {
    color: $AXS_TEXT_DARK;
    font-size: 1.33rem;
    font-weight: 500;
    padding-bottom: 1rem;
}

.icon {
    height: 3rem;
    width: 3rem;
}

.powerTrainSystemContainer{
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 3rem;
}


.text {
    color: $AXS_TEXT_DARK;
    font-size: .8rem;
    font-weight: 400;
    padding-bottom: 1rem;
    white-space: pre-line;
}

.releaseNotes{
    font-size: .8rem;
    font-weight: 400;
}

