@import '../../../constants/scss/constants.scss';
@import '../../../index.scss';

.bikeModelInfo {
    color: $SRAM_700;
    font-size: 1.25rem;
    padding-bottom: 2rem;
}

.buttonContainer {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin-top: auto;
    padding-top: 1rem;
    width: 100%;
    
}

.clickButton {
    background: none;
    border: none;
    color: $SRAM_RED;

    &:focus {
        outline: none;
    }
}

.container {
    display: flex;
    flex-direction: column;
    max-width: map-get($grid-breakpoints, lg);
    padding: 1rem;
}

.content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    max-width: map-get($grid-breakpoints, lg);
    padding-top: .5rem;
}

.description {
    color: $SRAM_700;
    font-size: 1.25rem;
    padding: 0.5rem;
    width: '100%';
}

.footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
}

.header {
    align-items: center;
    border-bottom: 1px solid $SRAM_600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 2rem;
}

.headerLogo {
    margin: 0 4rem;
    width: 12rem;
}

.headerDescription {
    padding: 1rem;
}

.helpButton {
    background: none;
    border: none;

    &:focus {
        outline: none;
    }
}

.image {
    margin: 1rem auto;
    max-width: 320px;
}

.imageContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.infoContent {
    color: initial;
    text-align: left;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
}

.infoSubHeader {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 30px;
}

.infoDetail {
    font-size: 14px;
    font-weight: 400;
}

.infoHeader {
    font-size: 20px;
    font-weight: 500;
}

.label {
    font-size: 1.5rem;
    font-weight: 1000;
    padding: 0.75rem 0;
    white-space: nowrap;
}

.link {
    color: inherit;
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

.modalContainer {
    padding: 1rem 2rem;
    max-width: map-get($grid-breakpoints, md);
    min-height: 30vh;
}

.noticeModalContainer {
    min-height: auto;
}

.noticeContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
}

.options {
    height: 100%;
}

.optionsContainer {
    max-width: 65rem;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;

}

.popover {
    padding: 1rem;
}

.row {
    border-bottom: 1px solid $SRAM_600;
    
}


select.inputField {
    appearance: none;
    background-color: transparent;
    background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%20%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%20%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2214px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2014%2012%22%20enable-background%3D%22new%200%200%2014%2012%22%20xml%3Aspace%3D%22preserve%22%3E%20%3Cpolygon%20points%3D%223.862%2C7.931%200%2C4.069%207.725%2C4.069%20%22/%3E%3C/svg%3E);
    background-position: 98% 50%;
    background-repeat: no-repeat;
    background-size: 2rem auto, 100%;
    border: 1px solid$SRAM_500;
    border-radius: 2px;
    cursor: pointer;
    font-size: 1rem;
    height: 2.25rem;
    padding: 0 1rem;
    width: 18rem;

}

.selectIcon {
    color: $SRAM_500;
}

.selectionButton {
    font-size: 1rem;
    font-weight: 700;
    height: 40px;
    outline: none;
    border: 1px solid $BLACK;
    width: 168px;
    border-radius: 2px;

    &:active {
        outline: none
    }

    &:focus {
        outline: none
    }
    
    &:hover {
        background-color:  $BLACK;
        color: $WHITE;
    }

}

.selectionButtonContainer {
    display: flex;
    justify-content: flex-start;
    padding-top: 1rem;
    width: 100%;
    gap: 2rem;
}

.selector {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
   
}

.setupGuideContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.submitButton {
    background-image: linear-gradient($PINK, $SRAM_RED 50%);
    border: none;
    border-radius: 4px;
    color: $WHITE !important;
    font-size: 1.25rem;
    font-weight: 1000;
    max-width: 30rem;
    padding: 1.25rem 2rem;
    text-transform: uppercase;
    width: 100%;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        color: $WHITE;
        text-decoration: none;
    }
}

.subTitle {
    font-size: 2rem;
    font-weight: 1000;
    padding: 0 0.5rem;
}

.title {
    font-size: 2rem;
    font-weight: 1000;
    padding: 0 0.5rem;
    width: '100%';
}

.toggleButton {
    background-color: $SRAM_800;
    border: 2px solid $SRAM_800;
    border-radius: 7px;
    color: $WHITE;
    font-size: 1.25rem;
    font-weight: 1000;
    padding: .75rem;
    width: 50%;

    &:hover {
        background-color: $SRAM_800;
        border-color: $SRAM_800;
    }
}

.toggler {
    background-color: $SRAM_800;
    border: 3px solid $SRAM_800;
    border-radius: 7px;
    margin: auto;
    max-width: 450px;
    width: 100%;
}

.togglerContainer {
    padding: 1rem 0;
}

.videoImage {
    width: 240px;
}

.videoTitle {
    font-size: 14px;
    color: black;
}
@media (max-width: map-get($grid-breakpoints, md)) {
    .container {
        padding: 1rem;
    }

    .description {
        font-size: 1rem;
    }

    .header {
        padding-bottom: 0.5rem;
    }

    .headerLogo {
        margin: 0 1rem;
        max-width: 60px;
    }

    .label {
        font-size: 1.25rem;
    }

    .row{
        padding: 1rem;
    }

    .selector {
        padding: 0;
    }

    .setupGuideContainer {
        flex-direction: column-reverse;
    }

    .subTitle {
        font-size: 1.25rem;
        font-weight: 400;
    }

    .title {
        font-size: 1.25rem;
    }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
    .content {
        display: flex;
        flex-direction: column;
    }

    .row {
        padding: 0
    }
    
   .selector {
        padding: 0;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

.menuItemNotListed {
    color: #FF1D37;
    margin-left: 8px;
    cursor: pointer;
    font-weight: 800;
    text-decoration: underline;
    font-family: "Roboto", sans-serif;

    &:hover {
        color: #FF1D37;
        text-decoration: underline;
    }
}